<template>
  <header>
    <div class="space"></div>
    <nav>
      <!-- for each route - get the current lang and add it to these paths :to="path"-->
      <router-link :to="'/' + locpath" class="topNavLink">{{ nav[setLang].home}}</router-link> | 
      <router-link :to="'/about' + locpath" class="topNavLink">{{ nav[setLang].about}}</router-link> | 
      <router-link :to="'/overview' + locpath" class="topNavLink">{{ nav[setLang].overview}}</router-link> | 
      <router-link v-if="setLang == 'fr'" :to="'/ressources' + locpath" class="topNavLink">{{ nav[setLang].resource}}</router-link> 
      <router-link v-else :to="'/resources' + locpath" class="topNavLink">{{ nav[setLang].resource}}</router-link> |
      <!-- <router-link to="/" class="topNavLink">French</router-link> -->
      <router-link v-if="setLang == 'en' && this.$route.name != 'Sector' && this.$route.name != 'Exposure' && this.$route.name != 'Ressources' && this.$route.name != 'Resources'" :to="this.$route.path+'?locale=fr'" class="topNavLink" v-on:click="changeLanguage()">{{ nav[setLang].lang}}</router-link>
      <router-link v-else-if="setLang == 'fr' && this.$route.name != 'Sector' && this.$route.name != 'Exposure' && this.$route.name != 'Ressources' && this.$route.name != 'Resources'" :to="this.$route.path+'?locale=en'" class="topNavLink" v-on:click="changeLanguage()">{{ nav[setLang].lang}}</router-link>
      <router-link v-else-if="setLang == 'fr' && this.$route.name == 'Sector'" :to="this.$route.path+locpath+'&locale=en'" class="topNavLink" v-on:click="changeLanguage()">{{ nav[setLang].lang}}</router-link>
      <router-link v-else-if="setLang == 'fr' && this.$route.name == 'Exposure'" :to="this.$route.path+locpath+'&locale=en'" class="topNavLink" v-on:click="changeLanguage()">{{ nav[setLang].lang}}</router-link>
      <router-link v-else-if="setLang == 'fr' && this.$route.name == 'Ressources'" :to="'/resources?locale=en'" class="topNavLink" v-on:click="changeLanguage()">{{ nav[setLang].lang}}</router-link>
      <router-link v-else-if="setLang != 'fr' && this.$route.name == 'Ressources'" :to="'/resources?locale=fr'" class="topNavLink" v-on:click="changeLanguage()">{{ nav[setLang].lang}}</router-link>
      <router-link v-else-if="setLang == 'fr' && this.$route.name == 'Resources'" :to="'/ressources?locale=en'" class="topNavLink" v-on:click="changeLanguage()">{{ nav[setLang].lang}}</router-link>
      <router-link v-else-if="setLang != 'fr' && this.$route.name == 'Resources'" :to="'/ressources?locale=fr'" class="topNavLink" v-on:click="changeLanguage()">{{ nav[setLang].lang}}</router-link>
        <router-link v-else-if="setLang == 'fr' && this.$route.name == 'Home'" :to="'/?locale=en'" class="topNavLink" v-on:click="changeLanguage('Home')">{{ nav[setLang].lang}}</router-link>
      <router-link v-else-if="setLang != 'fr' && this.$route.name == 'Home'" :to="'/?locale=fr'" class="topNavLink" v-on:click="changeLanguage('Home')">{{ nav[setLang].lang}}</router-link>
      
      <router-link v-else :to="this.$route.path+locpath+'&locale=fr'" class="topNavLink" v-on:click="changeLanguage()">{{ nav[setLang].lang}}</router-link>

    </nav>

    <div id="topLogos">
      <div class="container">
        <router-link :to="'/' + locpath">
          <div class="row">
            <div class="col-sm-3">
              <img class="img-fluid" :src="content[setLang].logo_left" alt="OCRC Logo" />
              </div>
            <div class="col-sm-9">
              <img class="img-fluid" :src="content[setLang].logo_right" alt="ODSS Title" />
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <!-- Sectors Dropdown -->
    <div id="sectorsDrop">
      <div class="container">
        <div class="row">
          <div class="col-sm-3">
          </div>
          <div class="col-sm-4 w-100">
            <label for="sel1" class="w-25 mt-2" style="font-size:75%;float:left;">{{ content[setLang].sectors}} &nbsp;&nbsp;</label>
            <dropdown :type="sectors" :n1="1" />
          </div>
          <div class="col-sm-5">
            <label for="sel1" class="w-25 mt-2" style="font-size:75%;float:left;">{{ content[setLang].exposures}} &nbsp;&nbsp;</label>
            <dropdown :type="exposures" :n1="2" />
          </div>
        </div>
      </div>
    </div>


    <!-- <div style="position:fixed;">
      <b-button v-b-modal.modal-1 style="background-color:#E7401C;color:white;margin:0px;z-index:1000000;transform: rotate(270deg);left:-35px;top:100px;position:fixed;">
      <p class="text-center" style="background-color:#E7401C;font-size:16px;font-family:verdana;top:10px;position:relative;"><b>Feedback</b></p>
      </b-button>
         <b-modal id="modal-1" style="height:100%;width:900px !important;display:block !important;" hide-footer hide-header>
      <script type="application/javascript">(function(t,e,s,n){var o,a,c;t.SMCX=t.SMCX||[],e.getElementById(n)||(o=e.getElementsByTagName(s),a=o[o.length-1],c=e.createElement(s),c.type="application/javascript",c.async=!0,c.id=n,c.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd9H5EX_2B27vj6m_2FMv9qEvml53ClB0Ye9ftC01dUoxWHCa.js",a.parentNode.insertBefore(c,a))})(window,document,"script","smcx-sdk");</script>
         </b-modal>
    </div> -->
  </header>
</template>

<script type="application/javascript">(function(t,e,s,o){var n,c,l;t.SMCX=t.SMCX||[],e.getElementById(o)||(n=e.getElementsByTagName(s),c=n[n.length-1],l=e.createElement(s),l.type="text/javascript",l.async=!0,l.id=o,l.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdz1yN6cctifesG4_2F0GaOzqcFbm19sh6lZyCVXcgHW3cG.js",c.parentNode.insertBefore(l,c))})(window,document,"script","smcx-sdk");</script>

<script>
import dropdown from '../components/dropdown.vue'

// Imports
import Vue from 'vue'
import VueHead from 'vue-head'
Vue.use(VueHead)

export default {
  components: {
    dropdown
  },
  data() {
    return{
      sectors: 'sectors',
      exposures: 'exposures',
      lang: 'en',
      nav:{
        en: {
          home:'Home',
          about:' About Us',
          overview: 'Overview',
          resource: 'Resources',
          lang: 'French'
        },
        fr:{
          home: 'Accueil',
          about: 'À propos de nous',
          overview: 'Aperçu',
          resource: 'Ressources',
          lang: 'Anglais'
        }
      },
      content: {
        en: {
          sectors: 'Sectors',
          exposures: 'Exposures',
          logo_left: '../img/ocrc_logo.svg',
          logo_right: '../img/odss_title.svg'
        },
        fr: {
          sectors: 'Secteurs',
          exposures: 'Expositions',
          logo_left: '../img/ocrc_logo_fr.svg',
          logo_right: '../img/odss_title_fr.svg'
        }
      }
    }
  },
   watch: {
    $route: function() {
      console.log("FROM:" + this.$route);
    }
  },
  // We use vue-head package to inject data into the <head> section here
  // This includes title, meta data, scripts, links, and styles etc..
  head: {
    title:{
      inner: 'Home | Ontario Occupational Disease Statistics'
    },
    meta:[
     // {he: 'Content-Security-Policy', c: "default-src * 'unsafe-inline' 'unsafe-eval'; script-src * 'unsafe-inline' 'unsafe-eval'; connect-src * 'unsafe-inline'; img-src * data: blob: 'unsafe-inline'; frame-src *; style-src * 'unsafe-inline';"}
    ],
    link: [
      { rel: 'stylesheet', href: '/css/bootstrap.min.css'},
      { rel: 'stylesheet', href: '/css/bootstrap-vue.css'},
      { rel: 'stylesheet', href: '/css/regular.min.css'},
      { rel: 'stylesheet', href: '/css/custom/custom_styles.css'},
      { rel: 'stylesheet', href: '/css/vue-multiselect.min.css'},
      { rel: 'stylesheet', href: '/css/fontawesome.min.css'},
    ],
    script: [
      //{ src: 'https://code.jquery.com/jquery-3.4.1.slim.min.js', integrity: 'sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n', crossorigin: 'anonymous'},
      { src: '/js/popper.min.js'},
      { src: '/js/bootstrap.min.js'},
      { src: '/js/regular.min.js'},
      { src: '/js/progressbar.js'}
    ]
  },
  mounted() {
    this.checkLanguage();
  },
  computed:{
    setLang : function () {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return lang;
    },
    locpath: function() {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      if (this.$route.name == "Sector" || this.$route.name == "Exposure") {
        return '?id=' + this.$route.query.id;
      } else {
        return '?locale='+lang;
      }
    }
  },
  methods: {
    checkLanguage(to='') {
      // Check if language param is set 
      var langParam = this.setLang;
      //var path = this.$route.path; // check if sector path, if yes keep the id

      // if not set - auto set to en
      if (!langParam) {
        this.$router.push({ query: Object.assign({}, this.$route.query, { locale: 'en' }) });
      }
      if (to == 'Home'){
        window.location.reload();
      }
    },
    changeLanguage() {
      var langParam = this.$route.query.locale;
      //var path = this.$route.path; // check if sector path, if yes keep the id

      // if not set - auto set to en
      if (langParam === 'en') {
        this.$router.push({ query: Object.assign({}, this.$route.query, { locale: 'fr' }) });
      } else {
        this.$router.push({ query: Object.assign({}, this.$route.query, { locale: 'en' }) });
      }
    }
  },
}
</script>
<style scoped>
.md-button{color:white !important;}
iframe{width:800px !important;}
</style>