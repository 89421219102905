<template>
    <div id="footer">
       <hr style="border: 0.2em solid #E7401C" />
        <footer>
            <div class="container footerContainer" v-if="this.$route.path == '/about' || this.$route.path == '/resources' ">
                <div class="row">
                    <div class="col-md-2 mb-2">
                        <a :href="content[setLang].ocrc_link"><img class="img-fluid" :src="content[setLang].ocrc_logo" alt="OCRC Logo" /></a>
                    </div>
                    <div class="col-md-2 mb-2">
                        <img class="img-fluid" :src="content[setLang].otl_logo" alt="Ontario Logo" />
                    </div>
                    <div class="col-md-2 mb-2">
                        <img class="img-fluid" src="../assets/wsib_logo.png" alt="WSIB Logo" />
                    </div>
                    <div class="col-md-2 mb-2">
                        <img class="img-fluid" :src="content[setLang].oh_logo" alt="Ontario Health Logo" />
                    </div>
                    <div class="col-md-2">
                       <img class="img-fluid" :src="content[setLang].ccs_logo" alt="CCS Logo" />
                    </div>
                    <div class="col-md-2 mb-3 text-center">
                        <div class="m-0" style="font-size:14px;"><span><small>{{content[setLang].hosted_text}}</small></span></div>
                        <a :href="content[setLang].our_link"><img class="img-fluid" :src="content[setLang].our_logo" alt="CCOHS Logo" /></a>
                    </div>
                </div>
            </div>
            <div class="container footerContainer" v-else>
                <div class="row">
                    <div class="col-md-3 mb-3">
                        <a :href="content[setLang].ocrc_link"><img class="img-fluid" :src="content[setLang].ocrc_logo" alt="OCRC Logo" /></a>
                    </div>
                    <div class="col-md-3 mb-3">
                        <img class="img-fluid" :src="content[setLang].otl_logo" alt="Ontario Logo" />
                    </div>
                    <div class="col-md-2 mb-3">
                        <img class="img-fluid" src="../assets/wsib_logo.png" alt="WSIB Logo" />
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-3 mb-3 text-center">
                        <div class="m-0" style="font-size:14px;"><span><small>{{content[setLang].hosted_text}}</small></span></div>
                        <a :href="content[setLang].our_link"><img class="img-fluid" :src="content[setLang].our_logo" alt="CCOHS Logo" /></a>
                    </div>
                </div>
            </div>
        </footer>
        <section id="grayBackground">
            <div class="container">
                <div class="row pt-3">
                    <div class="col-md-7" style="font-size:12px;" v-if="this.$route.path == '/sector'">
                        {{ content[setLang].footerDisclaimer }}
                    </div>
                    <div class="col-md-4 mr-4">
                        <p class="text-right">
                            <router-link :to="'/contact?locale='+setLang" class="topNavLink" style="color:white;"> {{ content[setLang].contact_text }}</router-link> 
                            <!-- | 
                            {{ content[setLang].report_text }} -->
                        </p>
                        <!-- <img class="img-fluid float-right p-1" src="../assets/icon.fb.svg" alt="Social Facebook" />
                        <img class="img-fluid float-right p-1" src="../assets/icon.linkedin.svg" alt="Social LinkedIn" /> -->
                        <a _target="_blank" href="https://twitter.com/ocrc_ca"><img class="img-fluid float-right p-1" src="../assets/icon.twitter.svg" alt="Social Twitter" /></a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
module.exports = {
    data: function () {
        return {
            content: {
                en:{
                    footerDisclaimer : 'Please note that results shown here may differ from those previously published or presented on the Occupational Disease Surveillance System. This may occur due to changes in case definitions, methodological approaches, and the ongoing nature of the surveillance cohort, including data linkage updates.',
                    contact_text: "Contact Us",
                    report_text: "Report Error",
                    hosted_text: "Hosted and maintained by",
                    ocrc_logo: "../img/ocrc_logo.svg",
                    otl_logo: "../img/otl.png",
                    oh_logo: "../img/oh-logo.svg",
                    our_logo: "../img/corp_ccohs_col.png",
                    ccs_logo: "../img/CCS_logo_eng.png",
                    our_link: "https://www.ccohs.ca/",
                    ccs_link: "https://www.cancer.ca/en/?region=on",
                    ocrc_link: "https://www.occupationalcancer.ca/",
                    ontario_link: "https://www.ontario.ca/page/government",
                    wsib_link: "https://www.wsib.ca/en"
                },
                fr:{
                    footerDisclaimer : "Veuillez noter que les résultats affichés peuvent différer de ceux ayant été publiés ou présentés antérieurement dans le Système de surveillance des maladies professionnelles. Cela peut être attribuable à l’évolution des définitions de cas, aux approches méthodologiques et au suivi en cours de la cohorte visée par le Système de surveillance, y compris les mises à jour du couplage de données. ",
                    contact_text: "Contactez-nous",
                    report_text: "Signaler une erreur",
                    hosted_text: "Hébergé et tenu par le CCHST",
                    ocrc_logo: "../img/ocrc_logo_fr.svg",
                    otl_logo: "../img/otl.png",
                    our_logo: "../img/corp_cchst.svg",
                    oh_logo: "../img/otl_fr.png",
                    ccs_logo: "../img/CCS_logo_fr.png",
                    our_link: "https://www.cchst.ca/",
                    ccs_link: "https://www.cancer.ca/fr-ca/?region=on",
                    ocrc_link: "https://www.occupationalcancer.ca/",
                    ontario_link: "https://www.ontario.ca/fr/page/gouvernement",
                    wsib_link: "https://www.wsib.ca/fr"
                }
            }
        } 
    },
    computed:{
        setLang : function () {
            var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
            return lang;
        },
        locpath: function() {
            var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
            if (this.$route.name == "Sector") {
                return '?id=' + this.$route.query.id;
            } else {
                return '?locale='+lang;
            }
        }
    }
}
</script>