import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery';
import '@babel/polyfill'
import "babel-core/register"
window.jQuery = $;
window.$ = $;

import HighchartsVue from "highcharts-vue";
import BootstrapVue from 'bootstrap-vue';
Vue.use(HighchartsVue)
Vue.use(BootstrapVue);
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-PHR7E66R03" }
}, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
