<template>
    <div>
        <!-- SECTORS DROPDOWN -->
        <div class="dropdown float-right w-75" v-if="droptype == 'sectors' && this.setLang =='fr'">
            <b-dropdown :text="choose_fr" class="text-alin-left w-100">
                <template v-slot:button-content>
                    {{ sector_text_fr }}  <b-icon-caret-down-fill class="caretIconDrop"></b-icon-caret-down-fill>
                </template>
                <b-dropdown-item v-for="sector in sortedSectors" class="w-100" :key="sector.id" :value="sector.id" @click="changeVal(sector.name_en, 'sector')">
                    <router-link class="dropdown-item" :to="{path: '/sector?id=' + sector.id + locpath}">
                        {{ sector.name }}
                    </router-link>
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="dropdown float-right w-75" v-else-if="droptype == 'sectors' && this.setLang == 'en'">
            <b-dropdown :text="choose" class="text-align-left w-100">
                <template v-slot:button-content>
                    {{ sector_text }}  <b-icon-caret-down-fill class="caretIconDrop"></b-icon-caret-down-fill>
                </template>
                <b-dropdown-item v-for="sector in sortedSectors" class="w-100" :key="sector.id" :value="sector.id" @click="changeVal(sector.name_fr, 'sector')">
                    <router-link class="dropdown-item w-100" :to="{path: '/sector?id=' + sector.id + locpath}">
                        {{ sector.name }}
                    </router-link>
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <!-- EXPOSURES DROPDOWN -->
        <div class="dropdown float-right w-75" v-if="droptype == 'exposures' && this.setLang == 'fr'">
            <b-dropdown :text="choose_fr" class="text-align-left w-100">
                <template v-slot:button-content>
                    {{ exposure_text_fr }}  <b-icon-caret-down-fill class="caretIconDrop"></b-icon-caret-down-fill>
                </template>
                <b-dropdown-item v-for="expo in sortedExposures" class="w-100" :key="expo.id" :value="expo.id" @click="changeVal(expo.name_fr, 'expo')">
                    <router-link class="dropdown-item w-100" :to="{path: '/exposure?id=' + expo.id + locpath}">
                        {{ expo.name }}
                    </router-link>
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="dropdown float-right w-75" v-else-if="droptype == 'exposures' && this.setLang == 'en' || this.setLang == ''">
            <b-dropdown :text="choose" class="text-align-left w-100">
                <template v-slot:button-content>
                    {{ exposure_text }}  <b-icon-caret-down-fill class="caretIconDrop"></b-icon-caret-down-fill>
                </template>
                <b-dropdown-item v-for="expo in sortedExposures" class="w-100" :key="expo.id" :value="expo.id" @click="changeVal(expo.name, 'expo')">
                    <router-link class="dropdown-item w-100" :to="{path: '/exposure?id=' + expo.id + locpath}">
                        {{ expo.name }}
                    </router-link>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueLodash, { lodash: lodash });

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// This imports the dropdown and table plugins
import DropdownPlugin from 'bootstrap-vue'
Vue.use(DropdownPlugin)

export default {
    data () {
        return {
            selectedSector: '',
            sectors: [],
            exposures: [],
            choose: 'CHOOSE',
            choose_fr: 'CHOISIR',
            sector_text: "",
            sector_text_fr: "",
            exposure_text: "",
            exposure_text_fr: ""
        }
    },
    props: [
      'type',
      'n1'
    ],
    mounted: function(){
        this.getSectors();
        this.getExposures();
    },
    computed: { 
        droptype: function () {
            let t = this.n1;

            if (t == 1) {
                return "sectors";
            }else {
                return "exposures";
            }
        },
        setLang : function () {
            var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
            return lang;
        }, 
        locpath: function () {
            var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
            return '&locale='+lang;
        },
        sortedSectors: function () {
            let sortedSectors = [];
            let sectors = this.sectors;
            let lang = this.setLang;
            this.lodash.forEach(sectors, function(d){
                if (lang == 'fr') {
                    sortedSectors.push({name: d.sector_name_fr, id:d.id, name_fr: d.sector_name_fr, name_en: d.sector_name});
                }else{
                    sortedSectors.push({name: d.sector_name, id:d.id, name_fr: d.sector_name_fr, name_en: d.sector_name});
                }
            });
            const sortedSeries = sortedSectors.sort((a,b) => a.name.localeCompare(b.name, 0, {caseFirst: 'lower'}))
            return sortedSeries;
        },
        sortedExposures: function () {
            let sortedExposures = [];
            let exposures = this.exposures;
            let lang = this.setLang;
            this.lodash.forEach(exposures, function(d){
                if (lang == 'fr') {
                    sortedExposures.push({name: d.exposure_name_fr, id:d.id, name_fr: d.exposure_name_fr, name_en: d.exposure_name});
                }else{
                    sortedExposures.push({name: d.exposure_name, id:d.id, name_fr: d.exposure_name_fr, name_en: d.exposure_name});
                }
            });
            const sortedSeries = sortedExposures.sort((a,b) => a.name.localeCompare(b.name, 0, {caseFirst: 'lower'}))
            return sortedSeries;
        }
    },
    methods: {
        getSectors() {
            var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
            axios.post(urloc+"/php/data.php?action=getDropSectors").then(response => {
                this.sectors = response.data;
                if (this.$route.name == 'Sector') {
                    let path1 = this.$route.query.id;
                    this.sector_text = this.sectors[path1].sector_name;
                    this.sector_text_fr = this.sectors[path1].sector_name_fr;
                    this.exposure_text = this.choose;
                    this.exposure_text_fr = this.choose_fr;
                } else {
                    this.sector_text = "CHOOSE";
                    this.sector_text_fr = "CHOISIR";
                    this.exposure_text_fr = "CHOOSE";
                    this.exposure_text_fr = "CHOISIR";
                }
            });
        },
        getExposures() {
            var urloc = (window.location.origin == "http://localhost:8080") ? "https://odss.ccohs.ca" : window.location.origin;
            axios.post(urloc+"/php/data.php?action=getDropExposures").then(response => {
                this.exposures = response.data;
                if (this.$route.name == 'Exposure') {
                    let path1 = this.$route.query.id;
                    this.exposure_text = this.exposures[path1].exposure_name;
                    this.exposure_text_fr = this.exposures[path1].exposure_name_fr;
                    this.sector_text = this.choose;
                    this.sector_text_fr = this.choose_fr;
                } else {
                    this.sector_text = "CHOOSE";
                    this.sector_text_fr = "CHOISIR";
                    this.exposure_text = "CHOOSE";
                    this.exposure_text_fr = "CHOISIR";
                }
            });
        },
        changeRoute() {
            this.$router.push({path:'/sector#' + this.selectedSector })
            if (this.$route.name == 'Sector') {
                let path1 = this.$route.query.id;
                if(this.setLang =='fr') {
                    this.sector_text_fr = this.sectors[path1].sector_name_fr;
                }else{
                    this.sector_text = this.sectors[path1].sector_name;
                }
            } else {
                let path1 = this.$route.query.id;
                if(this.setLang =='fr') {
                    this.exposure_text_fr = this.exposures[path1].exposure_name_fr;
                }else{
                    this.exposure_text =  this.exposures[path1].exposure_name;
                }
            }
        },
        changeVal(dropName, type) {
            if (type == "sector") {
                this.sector_text = dropName;
                this.sector_text_fr = dropName;
            } else {
                this.exposure_text = dropName;
                this.exposure_text_fr = dropName;
            }
            if (this.$route.name == 'Sector') {
                let path1 = this.$route.query.id;
                if(this.setLang =='fr') {
                    this.sector_text_fr = this.sectors[path1].sector_name_fr;
                }else{
                    this.sector_text = this.sectors[path1].sector_name;
                }
            } else {
                let path1 = this.$route.query.id;
                if(this.setLang =='fr') {
                    this.exposure_text_fr = this.exposures[path1].exposure_name_fr;
                }else{
                    this.exposure_text = this.exposures[path1].exposure_name;
                }
            }
        },
    }
}
</script>

<style>
.btn.dropdown-toggle, .btn.dropdown-toggle:hover, .btn-secondary.dropdown-toggle {
    background-color:white !important;
    border:0;
    color:black !important;
    text-align:left;
    height:35px;
    font-size:12px;
    font-weight:heavy;
    -webkit-box-shadow: 1px 3px 5px 0px rgba(89,86,89,1);
    -moz-box-shadow: 1px 3px 5px 0px rgba(89,86,89,1);
    box-shadow: 1px 3px 5px 0px rgba(89,86,89,1);
}
.btn.dropdown-toggle::after {
    display:none;
}

.show > .btn-secondary.dropdown-toggle {
    background-color:white;
    border:0;
    color:black;
}

.dropdown {
    padding-left:5px;
}

.caretIconDrop {
    float:right;
    color:#E7401C;
}

.dropdown > ul > li {
    width:100%;
}
</style>