<template>
  <!-- display highchart -->
  <div style="width:100%;padding:10px;text-align:center; border:2px dashed black;" v-if="this.empty == 1">
    <h2>{{content[setLang].nodata}}</h2>
  </div>
  <div style="display:inline-block;margin:0 auto;text-align:center;" v-else>
    <highcharts :options="chartOptions" ref="chart2"></highcharts>
  </div>
</template>

<script>
// Imports
import Vue from 'vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash });

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import hcMore from "highcharts/highcharts-more";
import hcDumbbell from "highcharts/modules/dumbbell";
import hcLollipop from "highcharts/modules/lollipop";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import offlineExporting from "highcharts/modules/offline-exporting";

Vue.use(Highcharts);
hcMore(Highcharts);
hcDumbbell(Highcharts);
hcLollipop(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
offlineExporting(Highcharts);

export default {
  components: {
    highcharts: Chart
  },
  mounted() {
    this.getChartData();
    if(this.chartOptions != undefined && this.seriesdata != []) {
      //this.chartOptions.series[0].setData(this.chart_series, true);
      this.redrawChart();
    }
  },
  watch:{
    //anytime a prop is changed - update chart data
    empty: function(){
      this.getChartData();
      this.redrawChart();
    },
    sector: function(){
      this.getChartData();
      this.redrawChart();
    },
    industry: function(){
      this.getChartData();
      this.redrawChart();
    },
    subIndustry: function(){
      this.getChartData();
      this.redrawChart();
    },
    disease: function(){
      this.getChartData();
      this.redrawChart();
    },
    occupation: function(){
      this.getChartData();
      this.redrawChart();
    },
    subgroup: function(){
      this.getChartData();
      this.redrawChart();
    },
    cancerous: function(){
      this.getChartData();
      this.redrawChart();
    },
    seriesdata:function() {
      if(this.chart != undefined && this.seriesdata != []) {
        //this.chart.series[0].setData(this.seriesdata, true);
        this.redrawChart();
      }
    },
    chart_series: function(){
      if(this.chart != undefined && this.chart_series != []) {
        //this.chart.series[0].setData(this.chart_series, true);
        this.redrawChart();
      }
    },
    chartOptions: function() {
      this.redrawChart();
    },
    setLang: function(){
      this.getChartData();
      this.redrawChart();
    }
  },
  computed: {
    setLang : function () {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return lang;
    },
    yaxist: function () {
      var lang = this.setLang;
      var text = (lang === 'fr') ? 'Augmentation ou diminution (en %) du taux d’incidence' : '% increase or decrease in the incidence rate';
      return text;
    }
   // ylabels: function () {
      // let opts = this.chartOptions.series[0].data;
      // var num = this.lodash.maxBy(opts, function(o){
      //   return o.low
      // });
      
      // Generate y-axis values based on min/max values in series data;
      //let opts = this.chartOptions.series[0].data;

//       var res = 
//   this.lodash(opts)
//   .groupBy('id')
//   .mapValues(a => ({
//     max: this.lodash.maxBy(a, 'low').low,
//     min: this.lodash.minBy(a, 'low').low
//   })).value();
  

//return Math.ceil(1);
  //  }
  },
  methods: {
    getImgUrl(path) {
      var images = require.context('../assets/', false, /\.svg$/);
      if (path != undefined) {
          return images('./' + path);
      }
    },
    redrawChart() {
      if (this.empty != 1 && this.$refs.chart2 != undefined){
        let ch2 = this.$refs.chart2.chart;
        let yaxisText = (this.setLang === 'fr') ? 'Augmentation ou diminution (en %) du taux d’incidence' : '% increase or decrease in the incidence rate';
        this.$refs.chart2.chart.yAxis[0].setTitle({text: yaxisText});
        ch2.redraw();
      }
    },
    getChartData() {
      let s = this.sector;
      let i = (this.subIndustry == 0) ? this.industry : this.subIndustry;
      let d = this.disease;
      let o = this.occupation;
      let g = this.subgroup;
      let c = this.cancerous;
      let lang = this.setLang;
      if (this.empty != 1 && this.$refs.chart2 != undefined){
        this.$refs.chart2.chart.series[0].setData([], true);
      }
      var urloc = window.location.origin;
      axios.get(urloc+"/php/data.php?action=getChartData&s="+s+"&i="+i+"&d="+d+"&o="+o+"&g="+g+"&c="+c).then(response => {
          this.chart_series = response.data;
          let series = [];
          this.lodash.forEach(response.data, function(d){
            let image = '/img/' + d.icon_name + '.svg';
            let diseaseName = (lang == 'fr') ? d.disease_name_fr : d.disease_name;
            let hr = parseFloat(d.hazard_ratio);
            hr = (hr < 1) ? -Math.abs((( 1 - (hr)) * 100).toFixed(0)) : (((hr) - 1)*100).toFixed(0);
            let seriesData = {
              name: diseaseName,
              low: parseFloat(hr),
              hazr: d.hazard_ratio,
              icon: image,
              ci: d.confidence_interval,
              workers: d.total_workers,
              cases: d.total_cases
            };
            series.push(seriesData);
          });
          this.chart_series = series;

          if (this.lodash.isEmpty(series, true)) {
            this.empty = 1;
          } else {
            this.empty = 0;
          }

          let size1 = this.lodash.size(series);

          if (size1 < 8) {
          this.chartOptions.chart.width = 0;
          } else {
            this.chartOptions.chart.width = 1000;
          }


          const sortedSeries = series.sort((a,b) => a.name.localeCompare(b.name, 0, {caseFirst: 'lower'}))
          if (this.empty != 1 && this.$refs.chart2 != undefined){
            this.$refs.chart2.chart.series[0].setData(sortedSeries, true);
          }
          this.chartOptions.series[0].data = sortedSeries;
          this.redrawChart();
      });
    },
    getOccupationGroups() {
      let sid = this.$route.query.id;
      let indid = this.industry;
      var urloc = window.location.origin;
      axios.get(urloc+"/php/data.php?action=getOccupationGroups&sid="+sid+"&indid="+indid).then(response => {
        this.occupation_groups = response.data;
      });
    },
  },
  props: [
    'sector',
    'industry',
    'subIndustry',
    'disease',
    'occupation',
    'subgroup',
    'cancerous',
    'seriesdata',
    'chartTitle'
  ],
  data() {
    return {
      redraw:true,
      empty:0,
      chart_series: this.seriesdata,
      occupation_groups: [],
      yaxisText: '% increase or decrease in the incidence rate',
      content:{
        en:{
          nodata: 'No data to show for this selection'
        },
        fr:{
          nodata: 'Aucune donnée pour cette sélection'
        }
      },
      chartOptions: {
        responsive: {  
          rules: [{  
            condition: {  
              maxWidth: 1000
            },  
            chartOptions: {  
              legend: {  
                enabled: false  
              }  
            }  
          }]  
        },
        chart: {
          renderTo: 'container',
          marginTop: 30,
            type: 'lollipop',
            width:1000,
            style: {
              fontFamily: 'verdana'
            },
            scrollablePlotArea: {
              minWidth: 20,
              scrollPositionX: 1,
            },
            events: {
              // load: function() {
              //   this.series[0].setData(this.chart_series);
              // },
              redraw() {
                //console.log("EVENT", event);
              }
            }
        },
        navigation: {
          buttonOptions: {
            verticalAlign: 'top', 
            x:-950,
            y: -15,
            theme: {
                states: {
                    hover: {
                        fill: '#e7401c'
                    },
                }
            }
          }
        },
        exporting: {
          chartOptions:{
            title:{
            text: this.chartTitle
            }
          },
          buttons: {
            contextButton:{
              symbol:null,
              text: "Download",
              symbolFill: '#e7401c',
              symbolStroke: '#e7401c'
            }
          }
        },
        accessibility: {
          point: {
            valueDescriptionFormat: '{index}. {xDescription}, {point.y}.'
          }
        },
        legend: {
          enabled: false
        },
        subtitle: {
          text: ''
        },
        title: {
          text: ''
        },
        tooltip: {
          shared: true,
          backgroundColor: "rgba(0,0,0,0)",
          useHTML: true,
          outside:true,
          style:{
            whiteSpace:'wrap',
          },
          formatter: function() {
            let num = Math.abs(this.y);
            let hr = this.points[0].point.hazr;
            let icon = (this.y > 0.99) ? 'fa-plus' : 'fa-minus';
            let iconColor = (this.y > 0.99) ? 'red' : 'green';

            return (
              '<div style="text-align:center;"><img src="'+this.points[0].point.icon+'" class="img-fluid" style="width:40px;" />' +
              '<p>' + this.points[0].key + '</p>' +
              '<div>HR: ' + Number.parseFloat(hr).toFixed(2) + ' (<i class="fa '+icon+'" style="font-size:80%;color:'+iconColor+';"></i>' + num+ '%)</div>' +
              '<div>CI: ' + this.points[0].point.ci + '</div></div>'
            );
          }
        },
        xAxis: {
          type: 'category',
          tickWidth:1,
          lineWidth:2,
         // tickInterval:1,
         // gridLineWidth: 1,
          labels: {
            //autoRotationLimit: 5,
           // rotation:310,
            overflow: 'show',
          },
          scrollbar: {
              enabled: true,
          },
          plotLines: [{
           // value: 10,
            width: 0,
          }],
        },
        yAxis: {
          className: 'highcharts-yaxis-0',
          gridLineWidth: 0,
          tickWidth:1,
          lineWidth:2,
          // min:-100,
          // max:100,
          title: {
              "textAlign": 'center',
              // "rotation": 0,
              // "width": '10',
              // x: 50,
              // y: 210
          },
          scrollbar: {
              enabled: true,
              showFull:false
          },
          labels:{
            useHTML: true,
            formatter:function(){
              if(this.value ==0){
                return "<div style='font-size:15px;'>0</div>";
              }
            }
          },
           plotLines: [{
            // value: 10,
            width: 3,
            value:0,
            fill: '#3C4148',
            color: '#3C4148',
            zIndex: 5
          }],
        },
        plotOptions: {
          lollipop: {
            className: 'main-color',
            lineColor: '',
            dataLabels: {
              enabled: true,
              useHTML: true,
              formatter: function() {
                let num = Math.abs(this.y);
                //num = (num > 99) ? 99 : num;
                if (this.y > 0.99) {
                  return '<div style="text-align:center;font-size:12px;"><i class="fa fa-chevron-up" style="color:red;"></i><br/> ' + num + '%</div>';
                } else {
                  return '<div style="text-align:center;font-size:12px;">' + num + '% <br/><i class="fa fa-chevron-down" style="color:green;"></i></div>';
                }
              }
            }
          },
        },
        series: [{
          name: 'Diseases',
          data: [],
          marker: {
            radius:5,
            fillColor: '#E7401C',
          },
          connectorColor: '#E7401C',
        }]
      }
    };
  }
};
</script>

<style>
.highcharts-yaxis-0{
  font-size:13px;
  /* stroke:#E7401C;
  fill:#E7401C; */
}
.highcharts-figure, .highcharts-data-table table {
    min-width: 320px; 
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
	font-family: Verdana, sans-serif;
	border-collapse: collapse;
	border: 1px solid #EBEBEB;
	margin: 10px auto;
	text-align: center;
	width: 100%;
	max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
	font-weight: 600;
    padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

.highcharts-plot-lines-0 > .highcharts-plot-line {
  fill: #3C4148;
}

.ld-label {
	width:200px;
	display: inline-block;
}

.ld-url-input {
	width: 500px; 
}

.ld-time-input {
	width: 40px;
}

.main-color .highcharts-graph{
	stroke: #E7401C;
}
.highcharts-lollipop-stem {
  stroke:#E7401C;
}
.highcharts-graph.highcharts-negative > .highcharts-lollipop-stem {
	stroke: #E7401C;
}
.main-color, .main-color .highcharts-point, .highcharts-lollipop-stem {
	fill: #E7401C;
}
.highcharts-graph.highcharts-negative {
	stroke: #E7401C;
}
.highcharts-area.highcharts-negative, .highcharts-lollipop-stem {
	fill: #E7401C;
}
.highcharts-point.highcharts-negative, .highcharts-lollipop-stem {
	fill: #E7401C;
  stroke-width:2;
}

.highcharts-tooltip-box {
	fill: white;
	fill-opacity: 0.8;
	stroke-width: 0;
}

.highcharts-tooltip text {
	fill: white;
  color:white;
	text-shadow: 0 0 3px black;
}
</style>