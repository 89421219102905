<template>
    <div>
        <label style="font-size:14px;"><b>{{title}}</b></label>

        <multiselect
            v-model="value"
            id="ajax"
            label="name"
            :placeholder="placeholder"
            track-by="name"
            :options="selectopts"
            :option-height="60"
            :show-labels="false"
            :loading="isLoading"
            @input="changeSelect"
            @search-change="asyncFind"
        >

            <template slot="singleLabel" slot-scope="props" style="width:100%;">
                <img class="option__image img-fluid" v-if="props.option.img != ''" :src="props.option.img" style="width:20px;margin-right:5px;" alt="">
                <span >
                    <span v-if="setLang == 'fr'">{{ props.option.name_fr }}</span>
                    <span v-else>{{ props.option.name }}</span>
                </span>
            </template>
            <template slot="option" slot-scope="props" style="width:100%;">
                <div class="option__desc">
                    <img class="option__image img-fluid" v-if="props.option.img != ''" :src="props.option.img" alt="" style="width:30px;margin-right:10px;">
                    <span class="option__title" style="font-size:15px;" v-if="setLang == 'fr'">{{ props.option.name_fr }}</span>
                    <span class="option__title" style="font-size:15px;" v-else>{{ props.option.name }}</span>
                    <span class="option__small">{{ props.option.desc }}</span>
                </div>
            </template>
            <span slot="noResult">
                <!-- No results error message can go here -->
            </span>
        </multiselect>
    </div>
</template>

<script>

import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.use(VueLodash, { lodash: lodash });
Vue.component('multiselect', Multiselect)

export default {
    data() {
        return {
            value: '',
            isLoading: false,
            opts1: this.options
        }
    },
    props: [
        'options',
        'diseases',
        'title',
        'typet',
        'cancerous'
    ],
    computed: {
        setLang : function () {
            var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
            return lang;
        },
        selectopts: function() {
            let filteredOpts = [];
            let type = this.typet;
            let cancerous = this.cancerous
            let lang = this.setLang;
            let diseases= this.diseases;

            this.lodash.forEach(this.options, function(ind){
                if (type == "disease") {
                    let imageIcon = (ind.icon_name != "") ? "/img/" + ind.icon_name + ".svg" : "";
                    if (cancerous == 0 && ind.is_cancerous == 1) {
                        filteredOpts.push({"name": ind.disease_name,"name_fr": ind.disease_name_fr, "img": imageIcon, "id": ind.id });
                    } else if (cancerous == 1 && ind.is_cancerous == 0) {
                        filteredOpts.push({"name": ind.disease_name, "name_fr": ind.disease_name_fr, "img": imageIcon, "id": ind.id });
                    }
                }
                if (type == "diseaseExposure") {
                    if (ind != undefined) {
                        let imageIcon = (diseases[ind.id].icon_name != "") ? "/img/" + diseases[ind.id].icon_name + ".svg" : "";
                        filteredOpts.push({"name": diseases[ind.id].disease_name,"name_fr": diseases[ind.id].disease_name_fr, "img": imageIcon, "id": diseases[ind.id].id });
                    }
                }
                if (type == "industry") {
                    filteredOpts.push({"name": ind.industry,"name_fr": ind.industry_fr, "id": ind.id, "img": '' });
                }

                if (type == "occuExposure") {
                    filteredOpts.push({"name": ind.occupation,"name_fr": ind.occupation_fr, "id": ind.id, "img": '' });
                }

                if (type == "occupation") {
                    var groups = '';
                    let occuName = (lang == 'fr') ? ind.occu_name_fr : ind.occu_name;
                    if (ind.groups != undefined && Object.keys(ind.subgroups).length > 0) {
                        if (lang == 'fr') {
                            groups = lodash.map(ind.subgroups, 'occu_name_fr').join(',');
                        }else{
                            groups = lodash.map(ind.subgroups, 'occu_name').join(',');   
                        }
                    } else {
                        groups = occuName;
                    }
                    filteredOpts.push({"name":  ind.occu_name,"name_fr":  ind.occu_name_fr, "img": "", "id": ind.id, "groups":groups});
                }
            });

            // Sort alpabetically and return list
            const sortedOpts = filteredOpts.sort((a,b) => a.name.localeCompare(b.name, 0, {caseFirst: 'lower'}))
            return sortedOpts;
        },
        placeholder: function () {
            let type = this.typet;
            let lang = this.setLang;
            if (type == "disease" || type == "diseaseExposure") {
                return (lang == "fr") ? "Sélectionnez une maladie" : "Select disease";
            }else if(type == "industry") {
                return (lang == "fr") ? "Sélectionnez ou cherchez une industrie" : "Select or search industry";
            }else if(type == "occuExposure") {
                return (lang == "fr") ? "Sélectionnez ou cherchez une profession" : "Select or search occupation";
            } else {
                return (lang == "fr") ? "Sélectionnez or recherchez un groupe professionnel" : "Select or search occupation group";
            }
        }
    },
    methods: {
        changeSelect() {
            this.asyncFind('');
        },
        asyncFind(query) {
            if (this.value != null) {
                this.$emit('searchselect', this.value.id, query);
            }
        },
    },
    watch: { 
        cancerous: function(newVal, oldVal) {
            console.log('Prop: ', newVal, ' | was: ', oldVal)
        },
        opts1: function(){
            console.log("upated");
        },
        options: function(){
            console.log("");
        },
        setLang: function() {
            console.log("");
        }
    }
}
</script>

<style>
    .multiselect__option{
    border-bottom:0.1px solid black;
    }
    .multiselect__option--highlight {
    background-color:#FEF5D7 !important;
    color:black !important;
    }
    .multiselect {
    max-height:60px;
    }
</style>