<template>
    <div style="position:fixed;">
        <b-button v-b-modal.modal-1 style="background-color:#E7401C;color:white;margin:0px;z-index:1000000;transform: rotate(270deg);left:-55px;top:100px;position:fixed;width:153px;">
            <p class="text-center" style="background-color:#E7401C;font-size:16px;font-family:verdana;top:10px;position:relative;">
                <b>{{content[setLang].feedback}}</b>
            </p>
        </b-button>
        <b-modal id="modal-1" style="height:100%;width:900px !important;display:block !important;" hide-footer hide-header>
            <script type="application/javascript">(function(t,e,s,n){var o,a,c;t.SMCX=t.SMCX||[],e.getElementById(n)||(o=e.getElementsByTagName(s),a=o[o.length-1],c=e.createElement(s),c.type="application/javascript",c.async=!0,c.id=n,c.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd9H5EX_2B27vj6m_2FMv9qEvml53ClB0Ye9ftC01dUoxWHCa.js",a.parentNode.insertBefore(c,a))})(window,document,"script","smcx-sdk");</script>
        </b-modal>
    </div>
</template>

<script>
export default {

    data() {
        return {
            content: {
                en:{
                    feedback: 'Feedback'
                },
                fr:{
                    feedback: 'Commentaires'
                }
            }
        }
    },
    computed:{
        setLang : function () {
            var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
            return lang;
        },
    }
}
</script>

<!-- fix layout issue at popup survey -->
<style>
	iframe{
		width: 500px !important;
		height: 400px !important;
	}
</style>