<template>
                    
    <!-- Begin table -->         
    <div class="expoChartContainer">       
    <table class="table table-striped">
        <thead>
            <tr class="exposure-table-headings">
                <th scope="col"></th>
                <th scope="col" class="text-center" colspan="3">{{content[setLang].measures_th}}</th>
                <th scope="col" class="text-center disease-td" style="background-color:rgba(250, 55, 48, 0.1)" :colspan="colspanD">{{content[setLang].risk_effects_th}}</th>
            </tr>
        </thead>
        <thead>
            <tr class="exposure-table-headings">
                <th width="350" scope="col"></th>
                <th width="120" class="text-center align-middle" v-html="content[setLang].proportion"></th>
                <th width="150" class="text-center align-middle" style="white-space: nowrap" v-html="content[setLang].intensity_level"></th>
                <th width="150" class="text-center align-middle" style="white-space: nowrap" v-html="content[setLang].duration"></th>
                <template v-for="(i,id) in diseaseList">
                  <template v-if="select_disease == 0">
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-if="i == 23 && setLang =='en'">
                    COPD
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if="i == 23 && setLang == 'fr'">
                    BCPO
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if=" i == 25 && setLang =='en'">
                    IPF
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if=" i == 25 && setLang == 'fr'">
                    FPI
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if=" i == 27 && setLang =='en'">
                    AMI
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if=" i == 27 && setLang == 'fr'">
                    IAM
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if="setLang == 'fr' && diseases[i].is_cancerous == 1 && i != 14">
                    {{diseases[i].disease_name_fr}} cancer
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if="setLang == 'en' && diseases[i].is_cancerous == 1 && i != 14">
                    {{diseases[i].disease_name}} cancer
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if="setLang == 'fr'">
                    {{diseases[i].disease_name_fr}}
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else>
                    {{diseases[i].disease_name}}
                </th>
                  </template>
                  <template v-else>
                    <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-if="i == 23 && setLang =='en' && select_disease == i">
                    COPD
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if="i == 23 && setLang == 'fr' && select_disease == i">
                    BCPO
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if=" i == 25 && setLang =='en' && select_disease == i">
                    IPF
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if=" i == 25 && setLang == 'fr' && select_disease == i">
                    FPI
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if=" i == 27 && setLang =='en' && select_disease == i">
                    AMI
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if=" i == 27 && setLang == 'fr' && select_disease == i">
                    IAM
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if="setLang == 'fr' && diseases[i].is_cancerous == 1 && i != 14 && select_disease == i">
                    {{diseases[i].disease_name_fr}} cancer
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if="setLang == 'en' && diseases[i].is_cancerous == 1 && i != 14 && select_disease == i">
                    {{diseases[i].disease_name}} cancer
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if="setLang == 'fr' && select_disease == i">
                    {{diseases[i].disease_name_fr}}
                </th>
                <th scope="col" class="disease-td text-center align-middle" :key="id+'-disease'" v-else-if="select_disease == i">
                    {{diseases[i].disease_name}}
                </th>
                    </template>
                </template>
            </tr>
        </thead>

        <!-- IF INDUSTRY TYPE -->
        <tbody v-if="tableType == 'industry'">
            <template v-for="(ind, index) in grouped">
                <tr width="100%" class="exposure-top-tr" :key="index" v-if="select_industry > 0 && select_industry == ind.id">
                    <td :colspan="colspanTH" class="align-middle" v-if="setLang == 'fr'">{{ind.industry_fr}}</td>
                    <td :colspan="colspanTH" class="align-middle" v-else>{{ind.industry}}</td>
                </tr>
                <tr width="100%" class="exposure-top-tr" :key="index" v-else-if="select_industry == 0">
                  <td :colspan="colspanTH" class="align-middle" v-if="setLang == 'fr'">{{ind.industry_fr}}</td>
                  <td :colspan="colspanTH" class="align-middle" v-else>{{ind.industry}}</td>
                </tr>
                <tr :key="index" v-else></tr>
                <template v-for="(group, index) in ind.groups">
                    <tr width="100%"  class="exposure-group-tr" v-if="group.industry_id == ind.id && group.industry != ind.industry" :key="index+'-100'">
                      <td :colspan="colspanTH" class="align-middle" v-if="setLang == 'fr'">{{group.industry_fr}}</td>
                      <td :colspan="colspanTH" class="align-middle" v-else>{{group.industry}}</td>
                    </tr>
                    <template v-for="(subgroup, index) in group.subgroups">
                        <tr width="100%" class="exposure-subgroup-tr" v-if="group.industry_id == ind.id && subgroup.industry_group_id == group.id" :key="index+'-1000'">
                            <td class="align-middle text-right" v-if="setLang == 'fr'">{{subgroup.industry_fr}}</td>
                            <td class="align-middle text-right" v-else>{{subgroup.industry}}</td>
                            <td>
                              <span v-if="setLang == 'fr'" v-tooltip.top-center="'<p>'+subgroup.industry_fr+'</p><table><tr><td><strong>'+content[setLang].exposed+':</strong></td><td>'+subgroup.intensity.exposed+'%</td></tr></table>'">
                                <circle-progress stroke-bg-color="#515151" stroke-color="#E7401C" :percentage="Math.round(subgroup.intensity.exposed)" :r="20"></circle-progress>
                              </span>
                              <span v-else v-tooltip.top-center="'<p>'+subgroup.industry+'</p><table><tr><td><strong>'+content[setLang].exposed+':</strong></td><td>'+subgroup.intensity.exposed+'%</td></tr></table>'">
                                <circle-progress stroke-bg-color="#515151" stroke-color="#E7401C" :percentage="Math.round(subgroup.intensity.exposed)" :r="20"></circle-progress>
                              </span>
                            </td>
                            <td class="semi-circle">
                              <span v-if="setLang == 'fr'" v-tooltip.top-center="
                              '<h6>'+subgroup.industry_fr+'</h6><br/><strong>'+content[setLang].intensity+': </strong>'+subgroup.intensity.intensity+'%<br/><br/><table><tr><td><strong>'+content[setLang].l_intensity+':</strong></td><td>'+subgroup.intensity.low_intensity+'%</td></tr><tr><td><strong>'+content[setLang].m_intensity+':&nbsp;</strong></td><td>'+subgroup.intensity.med_intensity+'%</td></tr><tr><td><strong>'+content[setLang].h_intensity+':</strong></td><td>'+subgroup.intensity.high_intensity+'%</td></tr></table>'">
                                <semi-circle-progress :stroke-width="10" stroke-bg-color="#515151" stroke-color="#E7401C" stroke-linecap="square" :percentage="Math.round(subgroup.intensity.intensity)" :r="20"></semi-circle-progress>
                              </span>
                              <span v-else v-tooltip.top-center="
                              '<h6>'+subgroup.industry+'</h6><br/><strong>'+content[setLang].intensity+': </strong>'+subgroup.intensity.intensity+'%<br/><br/><table><tr><td><strong>'+content[setLang].l_intensity+':</strong></td><td>'+subgroup.intensity.low_intensity+'%</td></tr><tr><td><strong>'+content[setLang].m_intensity+':&nbsp;</strong></td><td>'+subgroup.intensity.med_intensity+'%</td></tr><tr><td><strong>'+content[setLang].h_intensity+':</strong></td><td>'+subgroup.intensity.high_intensity+'%</td></tr></table>'">
                                <semi-circle-progress :stroke-width="10" stroke-bg-color="#515151" stroke-color="#E7401C" stroke-linecap="square" :percentage="Math.round(subgroup.intensity.intensity)" :r="20"></semi-circle-progress>
                                
                              </span>
                            </td>
                            <td class="align-middle">
                              <span v-if="setLang == 'fr'" v-tooltip.top-center="
                              '<h6>'+subgroup.industry_fr+'</h6><br/><strong>'+content[setLang].duration_text+': </strong>'+subgroup.intensity.duration+'%<br/><br/><table><tr><td><strong>'+content[setLang].duration1+':</strong></td><td>'+subgroup.intensity.duration_1+'%</td></tr><tr><td><strong>'+content[setLang].duration2+':</strong></td><td>'+subgroup.intensity.duration_2+'%</td></tr><tr><td><strong>'+content[setLang].duration3+':&nbsp;</strong></td><td>'+subgroup.intensity.duration_3+'%</td></tr><tr><td><strong>'+content[setLang].duration4+':</strong></td><td>'+subgroup.intensity.duration_4+'%</td></tr></table>'">
                                <progress-bar bg-color="#515151" color="#E7401C" :percentage="Math.round(subgroup.intensity.duration)" :height="10"></progress-bar>
                              </span>
                              <span v-else v-tooltip.top-center="
                              '<h6>'+subgroup.industry+'</h6><br/><strong>'+content[setLang].duration_text+': </strong>'+subgroup.intensity.duration+'%<br/><br/><table><tr><td><strong>'+content[setLang].duration1+':</strong></td><td>'+subgroup.intensity.duration_1+'%</td></tr><tr><td><strong>'+content[setLang].duration2+':</strong></td><td>'+subgroup.intensity.duration_2+'%</td></tr><tr><td><strong>'+content[setLang].duration3+':&nbsp;</strong></td><td>'+subgroup.intensity.duration_3+'%</td></tr><tr><td><strong>'+content[setLang].duration4+':</strong></td><td>'+subgroup.intensity.duration_4+'%</td></tr></table>'">
                                <progress-bar bg-color="#515151" color="#E7401C" :percentage="Math.round(subgroup.intensity.duration)" :height="10"></progress-bar>
                              </span>
                            </td>
                            <template v-for="(d, did) in diseaseList" functional>
                              <template v-for="(i,id) in subgroup.data">
                                <template v-if="d == i.disease_id">
                                  <td class="text-center align-middle disease-td" :key="id+'-'+did+'-'+index+'-idata'" v-if="i.hr != '--'">
                                    <span v-if="setLang == 'fr'" v-tooltip.top-center="'<h6>'+subgroup.industry_fr+'</h6><br/><strong>HR: </strong>'+i.hazard_ratio+'('+i.hr+')<br/><strong>95% CI: </strong>'+i.ci">
                                      {{i.hr}}
                                    </span>
                                    <span v-else v-tooltip.top-center="'<h6>'+subgroup.industry+'</h6><br/><strong>HR: </strong>'+i.hazard_ratio+'('+i.hr+')<br/><strong>95% CI: </strong>'+i.ci">
                                      {{i.hr}}
                                    </span>
                                  </td>
                                  <td class="text-center align-middle disease-td" :key="id+'-'+did+'-'+index+'-idata'" v-else>
                                    {{i.hr}}
                                  </td>
                                </template>
                              </template>
                            </template>
                        </tr>
                    </template>
                </template> 
            </template>
        </tbody>




        <!-- IF OCCUPATION -->
        <tbody v-else>
             <template v-for="(occu, index) in grouped" functional>
                <tr width="100%" class="exposure-group-tr" :key="index" v-if="select_occupation > 0 && select_occupation == occu.id">
                    <td :colspan="colspanTH" class="align-middle" v-if="setLang == 'fr'">{{occu.occupation_fr}}</td>
                    <td :colspan="colspanTH" class="align-middle" v-else>{{occu.occupation}}</td>
                </tr>
                <tr width="100%" class="exposure-group-tr" :key="index" v-else-if="select_occupation == 0">
                  <td :colspan="colspanTH" class="align-middle" v-if="setLang == 'fr'">{{occu.occupation_fr}}</td>
                  <td :colspan="colspanTH" class="align-middle" v-else>{{occu.occupation}}</td>
                </tr>
                <template v-for="(group, index) in occu.groups" functional>
                    <tr width="100%" v-if="group.occu_id == occu.id" :key="index+'-100'" class="exposure-subgroup-tr">
                        <td @click="toggleRows(group.id)" :class="{ opened: opened.includes(group.id) }" class="occupation-heading-th align-middle">
                          <i class="fa fa-minus-circle" style="float:left;padding-top:5px;" v-if="opened.includes(group.id)"></i>
                          <i class="fa fa-plus-circle" style="float:left;padding-top:5px;" v-else></i>&nbsp;
                          <span v-if="setLang == 'fr'" style="display:block;padding-left:20px;">{{group.occupation_fr}}</span>
                          <span v-else style="display:block;padding-left:20px;">{{group.occupation}}</span>
                        </td>
                        <td>
                          <span v-if="setLang == 'fr'" v-tooltip.top-center="'<h6>'+group.occupation_fr+'</h6><br/><strong>'+content[setLang].exposed+': </strong>'+group.intensity.exposed+'%'">
                            <circle-progress stroke-bg-color="#515151" stroke-color="#E7401C" :percentage="Math.round(group.intensity.exposed)" :r="20"></circle-progress>
                          </span>
                          <span v-else v-tooltip.top-center="'<h6>'+group.occupation+'</h6><br/><strong>'+content[setLang].exposed+': </strong>'+group.intensity.exposed+'%'">
                            <circle-progress stroke-bg-color="#515151" stroke-color="#E7401C" :percentage="Math.round(group.intensity.exposed)" :r="20"></circle-progress>
                          </span>
                        </td>
                        <td class="semi-circle">
                          <span v-if="setLang == 'fr'" v-tooltip.top-center="
                              '<h6>'+group.occupation_fr+'</h6><br/><strong>'+content[setLang].intensity+': </strong>'+group.intensity.intensity+'%<br/><br/><table><tr><td><strong>'+content[setLang].l_intensity+':</strong></td><td>'+group.intensity.low_intensity+'%</td></tr><tr><td><strong>'+content[setLang].m_intensity+':&nbsp;</strong></td><td>'+group.intensity.med_intensity+'%</td></tr><tr><td><strong>'+content[setLang].h_intensity+':</strong></td><td>'+group.intensity.high_intensity+'%</td></tr></table>'">
                                <semi-circle-progress :stroke-width="10" stroke-bg-color="#515151" stroke-color="#E7401C" stroke-linecap="square" :percentage="Math.round(group.intensity.intensity)" :r="20"></semi-circle-progress>
                          </span>
                          <span v-else v-tooltip.top-center="
                              '<h6>'+group.occupation+'</h6><br/><strong>'+content[setLang].intensity+': </strong>'+group.intensity.intensity+'%<br/><br/><table><tr><td><strong>'+content[setLang].l_intensity+':</strong></td><td>'+group.intensity.low_intensity+'%</td></tr><tr><td><strong>'+content[setLang].m_intensity+':&nbsp;</strong></td><td>'+group.intensity.med_intensity+'%</td></tr><tr><td><strong>'+content[setLang].h_intensity+':</strong></td><td>'+group.intensity.high_intensity+'%</td></tr></table>'">
                                <semi-circle-progress :stroke-width="10" stroke-bg-color="#515151" stroke-color="#E7401C" stroke-linecap="square" :percentage="Math.round(group.intensity.intensity)" :r="20"></semi-circle-progress>
                          </span>
                        </td>
                        <td style="padding-top:2.3%;">
                          <span v-if="setLang == 'fr'" v-tooltip.top-center="
                          '<h6>'+group.occupation_fr+'</h6><br/><strong>'+content[setLang].duration_text+': </strong>'+group.intensity.duration+'%<br/><br/><table><tr><td><strong>'+content[setLang].duration1+':</strong></td><td>'+group.intensity.duration_1+'%</td></tr><tr><td><strong>'+content[setLang].duration2+':</strong></td><td>'+group.intensity.duration_2+'%</td></tr><tr><td><strong>'+content[setLang].duration3+':&nbsp;</strong></td><td>'+group.intensity.duration_3+'%</td></tr><tr><td><strong>'+content[setLang].duration4+':</strong></td><td>'+group.intensity.duration_4+'%</td></tr></table>'">
                            <progress-bar bg-color="#515151" color="#E7401C" :percentage="Math.round(group.intensity.duration)" :height="10"></progress-bar>
                          </span>
                          <span v-else v-tooltip.top-center="
                          '<h6>'+group.occupation+'</h6><br/><strong>'+content[setLang].duration_text+': </strong>'+group.intensity.duration+'%<br/><br/><table><tr><td><strong>'+content[setLang].duration1+':</strong></td><td>'+group.intensity.duration_1+'%</td></tr><tr><td><strong>'+content[setLang].duration2+':</strong></td><td>'+group.intensity.duration_2+'%</td></tr><tr><td><strong>'+content[setLang].duration3+':&nbsp;</strong></td><td>'+group.intensity.duration_3+'%</td></tr><tr><td><strong>'+content[setLang].duration4+':</strong></td><td>'+group.intensity.duration_4+'%</td></tr></table>'">
                            <progress-bar bg-color="#515151" color="#E7401C" :percentage="Math.round(group.intensity.duration)" :height="10"></progress-bar>
                          </span>
                        </td>
                        <template v-for="(d, did) in diseaseList" functional>
                          <template v-for="(i,id) in group.data" functional>
                            <template v-if="d == i.disease_id">
                              <td class="disease-td text-center" :key="id+'-'+did+'-'+index+'-gdata'" v-if="i.hr != '--'">
                                <span v-if="setLang == 'fr'" v-tooltip.top-center="'<h6>'+group.occupation_fr+'</h6><br/><strong>HR: </strong>'+i.hazard_ratio+'('+i.hr+')<br/><strong>95% CI: </strong>'+i.ci">
                                  {{i.hr}}
                                </span>
                                <span v-else v-tooltip.top-center="'<h6>'+group.occupation+'</h6><br/><strong>HR: </strong>'+i.hazard_ratio+'('+i.hr+')<br/><strong>95% CI: </strong>'+i.ci">
                                  {{i.hr}}
                                </span>
                              </td>
                              <td class="disease-td text-center" :key="id+'-'+did+'-'+index+'-gdata'" v-else>
                                {{i.hr}}
                              </td>
                            </template>
                          </template>
                        </template>
                    </tr>
                    <template v-for="(subgroup, index) in group.subgroups" functional>
                        <!-- Loop through industry subgroups -->
                        <tr width="100%" row-type="bottom" class="exposure-subgroup-tr" v-if="opened.includes(group.id) && group.occu_id == occu.id && subgroup.occu_group_id == group.id" :key="index+'-1000'">
                            <td class="text-right align-middle" v-if="setLang == 'fr'">{{subgroup.occupation_fr}}</td>
                            <td class="text-right align-middle" v-else>{{subgroup.occupation}}</td>
                            <td>
                              <span v-if="setLang == 'fr'" v-tooltip.top-center="'<h6>'+subgroup.occupation_fr+'</h6><br/><strong>'+content[setLang].exposed+': </strong>'+subgroup.intensity.exposed+'%'">
                                <circle-progress stroke-bg-color="#515151" stroke-color="#E7401C" :percentage="Math.round(subgroup.intensity.exposed)" :r="20"></circle-progress>
                              </span>
                              <span v-else v-tooltip.top-center="'<h6>'+subgroup.occupation+'</h6><br/><strong>'+content[setLang].exposed+': </strong>'+subgroup.intensity.exposed+'%'">
                                <circle-progress stroke-bg-color="#515151" stroke-color="#E7401C" :percentage="Math.round(subgroup.intensity.exposed)" :r="20"></circle-progress>
                              </span>
                            </td>
                            <td class="semi-circle">
                              <span v-if="setLang == 'fr'" v-tooltip.top-center="
                                  '<h6>'+subgroup.occupation_fr+'</h6><br/><strong>'+content[setLang].intensity+': </strong>'+subgroup.intensity.intensity+'%<br/><br/><table><tr><td><strong>'+content[setLang].l_intensity+':</strong></td><td>'+subgroup.intensity.low_intensity+'%</td></tr><tr><td><strong>'+content[setLang].m_intensity+':&nbsp;</strong></td><td>'+subgroup.intensity.med_intensity+'%</td></tr><tr><td><strong>'+content[setLang].h_intensity+':</strong></td><td>'+subgroup.intensity.high_intensity+'%</td></tr></table>'">
                                    <semi-circle-progress :stroke-width="10" stroke-bg-color="#515151" stroke-color="#E7401C" stroke-linecap="square" :percentage="Math.round(subgroup.intensity.intensity)" :r="20"></semi-circle-progress>
                              </span>
                              <span v-else v-tooltip.top-center="
                                  '<h6>'+subgroup.occupation+'</h6><br/><strong>'+content[setLang].intensity+': </strong>'+subgroup.intensity.intensity+'%<br/><br/><table><tr><td><strong>'+content[setLang].l_intensity+':</strong></td><td>'+subgroup.intensity.low_intensity+'%</td></tr><tr><td><strong>'+content[setLang].m_intensity+':&nbsp;</strong></td><td>'+subgroup.intensity.med_intensity+'%</td></tr><tr><td><strong>'+content[setLang].h_intensity+':</strong></td><td>'+subgroup.intensity.high_intensity+'%</td></tr></table>'">
                                    <semi-circle-progress :stroke-width="10" stroke-bg-color="#515151" stroke-color="#E7401C" stroke-linecap="square" :percentage="Math.round(subgroup.intensity.intensity)" :r="20"></semi-circle-progress>
                              </span>
                            </td>
                            <td style="padding-top:2.3%;">
                              <span v-if="setLang == 'fr'" v-tooltip.top-center="
                              '<h6>'+subgroup.occupation_fr+'</h6><br/><strong>'+content[setLang].duration_text+': </strong>'+subgroup.intensity.duration+'%<br/><br/><table><tr><td><strong>'+content[setLang].duration1+':</strong></td><td>'+subgroup.intensity.duration_1+'%</td></tr><tr><td><strong>'+content[setLang].duration2+':</strong></td><td>'+subgroup.intensity.duration_2+'%</td></tr><tr><td><strong>'+content[setLang].duration3+':&nbsp;</strong></td><td>'+subgroup.intensity.duration_3+'%</td></tr><tr><td><strong>'+content[setLang].duration4+':</strong></td><td>'+subgroup.intensity.duration_4+'%</td></tr></table>'">
                                <progress-bar bg-color="#515151" color="#E7401C" :percentage="Math.round(subgroup.intensity.duration)" :height="10"></progress-bar>
                              </span>
                              <span v-else v-tooltip.top-center="
                              '<h6>'+subgroup.occupation+'</h6><br/><strong>'+content[setLang].duration_text+': </strong>'+subgroup.intensity.duration+'%<br/><br/><table><tr><td><strong>'+content[setLang].duration1+':</strong></td><td>'+subgroup.intensity.duration_1+'%</td></tr><tr><td><strong>'+content[setLang].duration2+':</strong></td><td>'+subgroup.intensity.duration_2+'%</td></tr><tr><td><strong>'+content[setLang].duration3+':&nbsp;</strong></td><td>'+subgroup.intensity.duration_3+'%</td></tr><tr><td><strong>'+content[setLang].duration4+':</strong></td><td>'+subgroup.intensity.duration_4+'%</td></tr></table>'">
                                <progress-bar bg-color="#515151" color="#E7401C" :percentage="Math.round(subgroup.intensity.duration)" :height="10"></progress-bar>
                              </span>
                            </td>
                            <template v-for="(d, did) in diseaseList" functional>
                              <template v-for="(i,id) in subgroup.data" functional>
                                <template v-if="d == i.disease_id">
                                  <td class="disease-td text-center" :key="id+'-'+did+'-'+index+'-odata'" v-if="i.hr != '--'">
                                    <span v-if="setLang == 'fr'" v-tooltip.top-center="'<h6>'+subgroup.occupation_fr+'</h6><br/><strong>HR: </strong>'+i.hazard_ratio+'('+i.hr+')<br/><strong>95% CI: </strong>'+i.ci">
                                      {{i.hr}}
                                    </span>
                                    <span v-else v-tooltip.top-center="'<h6>'+subgroup.occupation+'</h6><br/><strong>HR: </strong>'+i.hazard_ratio+'('+i.hr+')<br/><strong>95% CI: </strong>'+i.ci">
                                      {{i.hr}}
                                    </span>
                                  </td>
                                  <td class="disease-td text-center align-middle"  :key="id+'-'+did+'-'+index+'-odata'" v-else>
                                    {{i.hr}}
                                  </td>
                                </template>
                              </template>
                            </template>
                        </tr>
                    </template>
                </template>
            </template>
          
        </tbody>

    </table>
  </div>
</template>

<script>

import Vue from 'vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VuePithyProgress from 'vue-pithy-progress'
import 'vue-pithy-progress/lib/circle-progress.css'
import 'vue-pithy-progress/lib/progress-bar.css'
import 'vue-pithy-progress/lib/semi-circle-progress.css'

Vue.use(VuePithyProgress)
Vue.use(VueLodash, { lodash: lodash });

export default {
    data() {
        return {
            isLoading: false,
            content:{
              en:{
                measures_th: "Measures of Exposure",
                risk_effects_th: "Risk of adverse health effects (%)",
                sub_title: "Hover over icons to show % of data",
                exposed: "Proportion exposed overall",
                overall: "Overall",
                intensity: "Intensity",
                l_intensity: "Low intensity",
                m_intensity: "Medium intensity",
                h_intensity: "High intensity",
                duration_text: "Duration per work week (40 hrs)",
                duration: 'Duration <br/><small><strong>(% per work week)</strong></small>',
                duration1: "<2 hours",
                duration2: "2-12 hours",
                duration3: "12-39 hours",
                duration4: "40+ hours",
                proportion: "Proportion <br/><small><strong>(%)</strong></small>",
                intensity_level: "Intensity level <br/><small><strong>(%)</strong></small>"
              },
              fr:{
                measures_th: "Mesures de l’exposition",
                risk_effects_th: "Risque d’effets nocifs sur la santé (%)",
                sub_title: "Placez le pointeur sur une icône pour afficher le % des données",
                exposed: "Proportion exposés dans l'ensemble",
                overall: "Dans l'ensemble",
                intensity: "Intensité",
                l_intensity: "Intensité faible",
                m_intensity: "Intensité moyenne",
                h_intensity: "Intensité élevée",
                duration_text: "Durée par semaine de travail (40 heures)",
                duration: "Durée <br/><small>(% par semaine de travail)</small>",
                duration1: "<2 heures",
                duration2: "2-12 heures",
                duration3: "12-39 heures",
                duration4: "40+ heures",
                proportion: "Proportion <br/><small><strong>(%)</strong></small>",
                intensity_level: "Niveau d’intensité <br/><small><strong>(%)</strong></small>"
              },
            },
            colspanD: 0,
            colspanTH: 0,
            selecti: this.select_industry,
            opened: []
        }
    },
    props: [
        'tableType',
        'diseases',
        'industries',
        'industry_groups',
        'industry_subgroups',
        'industry_intensity',
        'industry_data',
        'grouped',
        'select_industry',
        'select_occupation',
        'select_disease',
        'occupations',
        'occu_groups',
        'occu_subgroups',
        'occu_group_intensity',
        'occu_sub_intensity',
        'occu_group_data',
        'occu_sub_data'
    ],
    mounted: function() {
      this.colspanDisease();
    },
    computed: {
      setLang : function () {
          var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
          return lang;
      },
      diseaseList: function() {
          let diseaseList = [];
          let typet = this.tableType;
          if (typet == "industry") {
            let ind_data = this.industry_data;
            this.lodash.forEach(ind_data, function(i){
                diseaseList.push({'id':i.disease_id});
            });
          }else {
            let occu_group_data = this.occu_group_data;
            this.lodash.forEach(occu_group_data, function(i){
                diseaseList.push({'id':i.disease_id});
            });
          }
          return this.lodash.chain(diseaseList).map('id').uniq().value();
      },
    },
    methods: {
      toggleRows(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1)
        } else {
          this.opened.push(id)
        }
      },
      colspanDisease() {
        let diseaseList = [];
        if (this.tableType == 'industry') {
          let ind_data = this.industry_data;
          this.lodash.forEach(ind_data, function(d){
            diseaseList.push({'id':d.disease_id});
          });
        } else {
          let occu_group_data = this.occu_group_data;
          this.lodash.forEach(occu_group_data, function(d){
            diseaseList.push({'id':d.disease_id});
          });
        }

        let dl = this.lodash.chain(diseaseList).map('id').uniq().value();
        this.colspanD = this.lodash.size(dl);
        this.colspanTH = this.lodash.size(dl) + 4;
      }    
    },
    watch: {
      exposureID: function() {
        this.colspanDisease();
      },
      diseaseList: function() {
        this.colspanDisease();
      }
    }
}
</script>

<style>
.inner-default-percentage, .bar-tip {display:none !important;}

.percent-text, .bar-tip {display:none !important;}

.disease-td {
  background-color:rgba(250, 55, 48, 0.1) !important;
}
.semi-circle .progress-content {display:none; background-color:transparent;}

.semi-circle svg path{stroke-width:10 !important;}

.progress-content:before{
    position: relative;
    font-family: 'FontAwesome';
    top: 0%;
    left:40%;
    font-size: 2em;
    content: "\f007";
}
.progress-inner{position:absolute !important;
}

.expoChartContainer {
  max-height:600px;
  max-width:auto;
  overflow-y:scroll;
}
</style>