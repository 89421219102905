import Vue from 'vue'
import VueRouter from 'vue-router'
import Sector from '../views/Sector.vue'
import Exposure from '../views/Exposure.vue'
Vue.use(VueRouter)

var lang = 'en'
var l = location.search.split('lang=')[1]
if (l == 'fr') {
  lang = 'fr'
} else {
  lang = 'en'
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import("../views/Main.vue"),
    query: { lang: lang }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import("../views/About.vue"),
    query: { lang: lang }
  },
  {
    path: '/sector',
    name: 'Sector',
    component: Sector,
    query: { lang: lang }
  },
  {
    path: '/exposure',
    name: 'Exposure',
    component: Exposure,
    query: { lang: lang }
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () => import("../views/Overview.vue"),
    query: { lang: lang }
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import("../views/Resources.vue"),
    query: { lang: lang }
  },
  {
    path: '/ressources',
    name: 'Ressources',
    component: () => import("../views/ResourcesFrench.vue"),
    query: { lang: lang }
  },
  {
    path: '/transcript',
    name: 'Transcript',
    component: () => import("../views/Transcript.vue"),
    query: { lang: lang }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import("../views/Contact.vue"),
    query: { lang: lang }
  }
]

const router = new VueRouter({
  routes
})

export default router
