<template>
  <section>
    <Header/>
    <div id="app">
      <router-view/>
    </div>
    <Footer/>
  </section>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
export default {
  
}
</script>


<style type="text/css">
  #sectors { 
    border: 1px solid #F26F35;
    border-radius: 120px;
    margin-top:3%;
  }

  .container {
    max-width:2000px !important;
  }

  .introText, .video, #sectors, .infoMain{
    margin:0 auto;
    padding:0;
    width:70%;
  }
</style>
