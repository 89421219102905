
<template>
    <section>

     <!-- Include Header -->
        <Header />

        <!-- main section -->
        <section class="main">
          <Feedback />

            <!-- Sector Title -->
            <section class="topBar" style="background-color:#1B355E;margin-top:0;color:white;text-shadow: 0px 3px 6px #00000091;">
                <h1 style="text-align:left;margin-left:10%;font-size:4vw;padding:10px 0px 10px 0px;">{{ dropName }}</h1>
            </section>

            <div id="topLogos">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-9">
                            <h2 style="color:#3C4148"><b>{{content[setLang].who_text}}</b></h2>
                            <p style="text-align:left;color:#3C4148;font-size:1.1em" v-html="dropDescription"></p>
                        </div>
                        <div class="col-sm-3 text-center" style="font-size:12px;">
                            <img class="img-fluid" :src="getSectorIcon()" alt="Sector Icon" />
                            <div class="mt-3 pl-3" style="border-left:1px solid #E7401C;font-size:14px;">
                                <p class="font-weight-bold">{{content[setLang].some_text}}</p>
                                <!-- <div v-if="sectorID == 8" v-html="keyExposures" style="font-size:12px;"></div> -->
                                <div v-html="keyExposures"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="sectorID == 7">
                  <p>
                    <router-link :to="'/sector?id=4&locale=' + setLang" class="topNavLink" v-on:click="showExplore = true">
                      <img class="img-fluid" :src="content[setLang].explore_pm" :style="content[setLang].pm_style" v-if="!showExplore" alt="Show Data Tool" />
                    </router-link>
                  </p>
                <router-link :to="'/sector?id=5&locale=' + setLang" class="topNavLink" v-on:click="showExplore = true">
                  <img class="img-fluid" :src="content[setLang].explore_mf" :style="content[setLang].mf_style" v-if="!showExplore" alt="Show Data Tool" />
                </router-link>
                </div>
                <div v-else-if="sectorID == 4">
                  <div class="container">
                    <div class="row">
                        <div class="col-lg-6 text-center">
                          <router-link :to="'/sector?id=4&locale=' + setLang" v-on:click="showExplore = true">
                            <img class="img-fluid" :src="content[setLang].pm_link_active" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                          </router-link>
                        </div>
                        <div class="col-lg-6 text-center">
                          <router-link :to="'/sector?id=5&locale=' + setLang" v-on:click="showExplore = true">
                            <img class="img-fluid" :src="content[setLang].mf_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                          </router-link>
                        </div>
                    </div>
                </div>
                </div>
                <div v-else-if="sectorID == 5">
                  <div class="container">
                    <div class="row">
                        <div class="col-lg-6 text-center">
                          <router-link :to="'/sector?id=4&locale=' + setLang" v-on:click="showExplore = true">
                            <img class="img-fluid" :src="content[setLang].pm_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                          </router-link>
                        </div>
                        <div class="col-lg-6 text-center">
                          <router-link :to="'/sector?id=5&locale=' + setLang" v-on:click="showExplore = true">
                            <img class="img-fluid" :src="content[setLang].mf_link_active" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                          </router-link>
                        </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <img class="img-fluid" :src="content[setLang].data_tool" style="width:250px;float:left;cursor:pointer;" v-if="!showExplore" v-on:click="showExplore = !showExplore" alt="Show Data Tool" />
                  <p v-if="!showExplore"><br/></p>
                </div>
            </div>

            <div id="glanceData" v-if="!showExplore && sectorID != 4 && sectorID != 5">
                <div v-if="setLang == 'fr'">
                  <div style="border:1px solid #E7401C;;text-align:center;">
                  <img class="img-fluid m-5" style="width:90%;" :src="'/img/'+(dropText.toLowerCase()).replace(/ /g, '')+'_infographic_fr.svg'" :alt="(dropText.toLowerCase()).replace(/ /g, '') + ' infographic'" />
                  </div>
                  <div style="width:20%;margin-top:-25px;margin-left:71%;background:white;">
                  <a :href="'/img/'+(dropText.toLowerCase()).replace(/ /g, '')+'_infographic_fr.svg'" :download="'ODSS_'+(dropText.toLowerCase()).replace(/ /g, '')+'_sector_infographic_fr.svg'">
                      <p class="downloadBtn">{{content[setLang].download_text}}</p>
                  </a>
                  </div>
                </div>

                <div v-else>
                  <div style="border:1px solid #E7401C;;text-align:center;">
                  <img class="img-fluid m-5" style="width:90%;" :src="'/img/'+(dropText.toLowerCase()).replace(/ /g, '')+'_infographic.svg'" :alt="(dropText.toLowerCase()).replace(/ /g, '') + ' infographic'" />
                  </div>
                  <div style="width:20%;margin-top:-25px;margin-left:71%;background:white;">
                  <a :href="'/img/'+(dropText.toLowerCase()).replace(/ /g, '')+'_infographic.svg'" :download="'ODSS_'+(dropText.toLowerCase()).replace(/ /g, '')+'_sector_infographic.svg'">
                      <p class="downloadBtn">{{content[setLang].download_text}}</p> 
                  </a>
                  </div>
                </div>
        
                <br/><br/><br/>
            </div>


            <!-- Accordian main start - overview and industries -->
            <div id="exploreData" v-if="showExplore || (sectorID == 4 || sectorID == 5)">

                <div id="topLogos">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6">
                        <a href="#" v-scroll-to="'#anchorIndustry'">
                          <img class="img-fluid" src="../assets/anchorIndustry.svg" style="width:30px;float:left;text-align:left;" alt="Anchor Industry" />
                          <p style="text-align:left;padding:5px 0px 0px 35px;font-size:1em;margin-top:-5px;" >{{content[setLang].scroll_text}}</p>
                        </a>
                      </div>
                      <div class="col-md-6" v-if="sectorID == 4 || sectorID == 5">
                        <router-link :to="'/sector?id=7&locale=' + setLang"><p style="cursor:pointer;text-align:right;" class="viewKeyFindings">{{content[setLang].view_key}}</p></router-link>
                      </div>
                      <div class="col-md-6" v-else>
                        <a v-on:click="showExplore = !showExplore"><p style="cursor:pointer;text-align:right;" class="viewKeyFindings">{{content[setLang].view_key}}</p></a>
                      </div>
                    </div>
                  </div>
                </div>
          <template>
            <div role="tablist" style="margin-left:14%;margin-right:14%;">

              <!-- Overview Sector -->
              <b-card no-body class="mb-1" style="background-color:#0A2940;">

                <!-- Overview Header -->
                <b-card-header v-on:click="accordianIndustry(0)" header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                  <b-button class="accTitle" block v-b-toggle.accordion-0 variant="info" style="background-color:#0A2940;border:none;font-size:2em !important;" data-toggle="collapse">
                    <b v-if="sectorID == 8 && setLang =='fr'">{{content[setLang].occu_overall}}</b>
                    <b v-else-if="sectorID == 8 && setLang =='en'">{{dropName}} {{content[setLang].occu_overall}}</b>
                    <b v-else>{{dropName}} {{content[setLang].ind_overall}}</b>

                    <span class="when-opened">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="overview minus" />
                    </span>
                    <span class="when-closed">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="overview minus" />
                    </span>
                  </b-button>
                </b-card-header>

                <!-- Collapsed Content for Overview -->
                <b-collapse id="accordion-0" visible accordion="my-accordion" role="tabpanel" style="color:white;margin:0;">
                  <b-card-body>

                    <hr style="background-color:white;margin-top:-15px;" />
                    <p style="text-align:left;margin-top:-10px;">
                      {{content[setLang].result_text1}} {{dropName.toLowerCase().trim()}} {{content[setLang].result_text2}}
                    </p>


                    <!-- Multiselects for Occupations and Diseases -->              
                    <div class="container" style="">
                      <div class="row">
                        <div class="col-md-6 float-left">
                          <Multiselect v-if="sectorID == 8" v-model="select_occupation" :options="select_occugroups" :title="content[setLang].select_occu" :typet="'occupation'" v-on:searchselect="changeOccupation" :key="occupationKey"></Multiselect>
                          <Multiselect v-else v-model="select_occupation" :options="select_occugroups" :title="content[setLang].select_occu2" :typet="'occupation'" v-on:searchselect="changeOccupation" :key="occupationKey"></Multiselect>
                        </div>
                        <div class="col-md-3 w-100" style="font-size:0.9vw;">
                          
                         <p style="font-size: 1.1vw;margin-top:40px;">Cancers<b-form-checkbox v-model="isCancerous" switch size="lg" style="float:right;height:60px;" :key="toggleCancer"><p style="font-size:1.1vw;">Non-Cancers</p></b-form-checkbox></p>
                        </div>
                        <div class="col-md-3 float-right">
                           <Multiselect v-model="select_disease" :options="diseases" :title="content[setLang].select_disease" :typet="'disease'" :cancerous="isCancerous" v-on:searchselect="changeDisease" :key="diseaseKey"></Multiselect>
                        </div>
                      </div>
                    </div>


                    <!-- Options and Highcharts section -->
                    <div style="padding:10px;background-color:white;color:black;font-size:12px;">
                      <div class="container" style="">

                        <!-- Row of info and select cancers/non-cancer -->
                        <div class="row">
                          <div class="col-sm-12 h-100" v-if="select_description" style="background-color:#FEF5D7;margin-bottom:10px;padding:10px;color:black;">
                            {{ select_description }}
                            </div>
                        </div>
                         <div class="row">
                           <div class="col-sm-12 h-100">
<br/>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 h-100">
                            <div style="border:0.2px solid black;padding:5px;text-align:center;">
                              <span style="margin-right:10px"><i class="fa fa-chevron-up" style="color:red;"></i> {{content[setLang].increased_risk}}</span>
                              <span><i class="fa fa-chevron-down" style="color:green;"></i> {{content[setLang].reduced_risk}}</span></div>
                            </div>


                          <div class="col-md-3 h-100 d-flex justify-content-center">
                            <v-popover offset="16" style="cursor:pointer;">
                              <div class="tooltip-target b3">
                                <img style="width:20%;float:left;margin-right:5px;" src="../assets/data_tool_help.svg" alt="data tool help icon" />
                                <div style="padding-left:5px;font-weight:bold;font-size:13px;width:80%;">{{content[setLang].how_title}}</div>
                              </div>
                              <template slot="popover">
                                <div>
                                  <h6 class="font-weight-bold">{{content[setLang].how_title}}</h6> 
                                  <ol type="1" class="ml-3">
                                    <li>{{content[setLang].how1}}</li><br>
                                    <li>{{content[setLang].how2}}</li><br>
                                    <li>{{content[setLang].how3}}</li><br>
                                    <li>{{content[setLang].how4}}</li>
                                  </ol>
                                </div>
                              </template>
                            </v-popover>
                          </div>

                            <div class="col-md-3 h-100 d-flex justify-content-center">
                              <v-popover offset="16" style="cursor:pointer;">
                              <div class="tooltip-target b3">
                                <img style="width:25%;float:left;margin-right:5px;" src="../assets/interpret_data_help.svg" alt="interpret data icon" />
                                <div style="padding-left:5px;font-weight:bold;font-size:13px;width:75%;">{{content[setLang].interpret_title}}</div>
                              </div>
                              <template slot="popover">
                                <div>
                                  <h6 class="font-weight-bold">{{content[setLang].interpret_title}}</h6> 
                                  <p>{{content[setLang].interpret1}}</p>
                                  <p>{{content[setLang].interpret2}}</p>
                                  <p>{{content[setLang].interpret3}}</p>
                                  <p>{{content[setLang].interpret4}}</p>
                                </div>
                              </template>
                            </v-popover>
                            </div>
                            
                            <div class="col-md-2 h-100 float-right">
                             <p class="clearBtn" v-on:click="reset">{{content[setLang].clear_select}}</p>
                            </div>

                          </div>
                          
                        </div>
                        
                        <!-- Load Highcharts -->
                          <!-- pass all info to highcharts so we can load the data; if industry = 0 = overview -->
                          <h5 align=center class="mt-2">{{chartTitle}}</h5>
                          <p align=center class="font-weight-bold" style="color:#E7401C;">
                            <i class="fa fa-circle mr-1"></i>
                            {{content[setLang].sub_title}}
                          </p>
                          <div class="container" style="text-align:center;">
                            <div class="row" style="width:100%;overflow-x:scroll;display:inline-block;text-align:center;">
                          <highcharts 
                            id="chart1"
                            style="display:inline-block;overflow:auto;"
                            :sector="this.$route.query.id"
                            :industry="visibleIndustry" 
                            :disease="select_disease"
                            :occupation="select_occupation"
                            :subgroup="select_suboccupation"
                            :cancerous="isCancerous"
                            :seriesdata="chart_series"
                            :chartTitle="chartTitle"
                          >
                          </highcharts>
                          </div>
                          </div>
                        

                        <div class="container" style="padding:0px 10px 20px 10px;">
                          <div class="row">
                            <div class="col-sm-12" v-if="occupation_groups[select_occupation] != undefined && occupation_groups[select_occupation]['subgroups'].length != 0" style="background-color:#EAEAEB;padding:10px;color:black;">
                              <!-- Radio buttons for occupation subgroups from Occupation multiselect above -->
                              <h5 v-if="sectorID == 8" style="color:#0A2940;"><b>{{content[setLang].select_job2}}:</b></h5>
                              <h5 v-else style="color:#0A2940;"><b>{{content[setLang].select_job}}:</b></h5>
                              <div class="list-group d-flex flex-row flex-wrap">

                                <b-form-group class="w-50" v-for="(og, index) in occupation_groups[select_occupation]['subgroups']" :key="index">
                                  <b-form-radio v-model="select_suboccupation" primary :id="og.id" :value="og.id" :checked="og.id == select_suboccupation" v-if="setLang == 'fr'">{{og.occu_name_fr}}</b-form-radio>
                                  <b-form-radio v-model="select_suboccupation" primary :id="og.id" :value="og.id" :checked="og.id == select_suboccupation" v-else>{{og.occu_name}}</b-form-radio>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <!-- <h2 v-if="dropName == 'Construction'" class="p-3" style="text-align:center;"><b>The {{dropName}} Industry at a Glance</b></h2>
                        <img v-if="dropName == 'Construction'" class="img-fluid" :src="'/img/'+dropName.toLowerCase()+'_infographic.png'" style=" display: block;margin-left: auto;margin-right: auto;"/>
                        <div class="container">
            <div class="row p-3">

                     <div class="col-md-4 mr-5">
                        <a :href="'/img/'+dropName.toLowerCase()+'_infographic.png'" download>
            <p class="downloadBtn">Download Infographic</p></a>
                    </div
                </div>
                </div> -->
                      </div> <!-- End Container -->
               
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- create a computed property to filter these and only display items with minor_id = 0 -->
              <div v-for="(industry, index) in industries" :key="index">
              <b-card no-body ref="anchorIndustry" id="anchorIndustry" class="mb-1" style="background-color:#778794;" v-if="industry.major_id != sectors[industry.sector_id].sector_code">
                <b-card-header v-on:click="accordianIndustry(industry.id)" header-tag="header" class="p-1" role="tab" style="background-color:#778794;border:none;" data-toggle="collapse">
                  <b-button block v-b-toggle="'accordion-' + industry.id" variant="info" style="background-color:#778794;border:none;font-size:1.4em !important;margin-top:0px;">
                    <b v-if="setLang == 'fr' && sectorID != 8 && sectorID != 9">{{industry.industry_name_fr}} industrie</b>
                    <b v-else-if="setLang == 'en' && (sectorID == 8 || sectorID == 9)">{{industry.industry_name}}</b>
                    <b v-else-if="setLang == 'fr' && (sectorID == 8 || sectorID == 9)">{{industry.industry_name_fr}}</b>
                    <b v-else>{{industry.industry_name}} Industry</b>

                  
                  
                    <span class="when-opened">
                      <img class="img-fluid" style="float:right;width:40px;" src="../assets/sector_minus.svg" alt="sector minus" />
                    </span>
                    <span class="when-closed">
                      <img class="img-fluid" style="float:right;width:40px;" src="../assets/sector_plus.svg" alt="sector minus" />
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse :id="'accordion-' + industry.id" accordion="my-accordion" role="tabpanel" style="color:white;margin:0;">
                  <b-card-body>
                    <hr style="background-color:white;margin-top:-18px;" />
                    <p style="text-align:left;;margin-top:-13px;">
                      {{content[setLang].result_text1}} {{dropName.toLowerCase().trim()}} {{content[setLang].result_text2}}
                    </p>
                    
                      
                    <!-- Multiselects for Occupations and Diseases -->              
                    <div class="container">
                      <div class="row">
                        <div class="col-md-6 float-left">
                          <Multiselect v-if="sectorID == 8" v-model="select_occupation" :options="select_occugroups" :title="content[setLang].select_occu" :typet="'occupation'" v-on:searchselect="changeOccupation" :key="occupationKey"></Multiselect>
                          <Multiselect v-else v-model="select_occupation" :options="select_occugroups" :title="content[setLang].select_occu2" :typet="'occupation'" v-on:searchselect="changeOccupation" :key="occupationKey"></Multiselect>
                        </div>
                        <div class="col-md-3 w-100" style="font-size:0.9vw;">
                          
                         <p style="font-size: 1.1vw;margin-top:40px;">Cancers<b-form-checkbox v-model="isCancerous" switch size="lg" style="float:right;height:60px;" :key="toggleCancer"><p style="font-size:1.1vw;">Non-Cancers</p></b-form-checkbox></p>
                        </div>
                        <div class="col-md-3 float-right">
                           <Multiselect v-model="select_disease" :options="diseases" :title="content[setLang].select_disease" :typet="'disease'" :cancerous="isCancerous" v-on:searchselect="changeDisease" :key="diseaseKey"></Multiselect>
                        </div>
                      </div>
                    </div>


                    <!-- Options and Highcharts section -->
                    <div style="padding:10px;background-color:white;color:black;font-size:12px;">
                      <div class="container" style="padding-bottom:50px;">

                        <!-- Row of info and select cancers/non-cancer -->
                        <div class="row">
                          <div class="col-sm-12 h-100" v-if="select_description" style="background-color:#FEF5D7;padding:10px;margin-bottom:10px;color:black;">
                              {{ select_description }}
                            </div>
                        </div>
                         <div class="row">
                           <div class="col-sm-12 h-100">
<br/>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 h-100">
                            <div style="border:0.2px solid black;padding:5px;text-align:center;">
                              <span style="margin-right:10px"><i class="fa fa-chevron-up" style="color:red;"></i> {{content[setLang].increased_risk}}</span>
                              <span><i class="fa fa-chevron-down" style="color:green;"></i> {{content[setLang].reduced_risk}}</span></div>
                            </div>

                            <div class="col-md-3 h-100 d-flex justify-content-center">
                            <v-popover offset="16" style="cursor:pointer;">
                              <div class="tooltip-target b3">
                                <img style="width:20%;float:left;margin-right:5px;" src="../assets/data_tool_help.svg" />
                                <div style="padding-left:5px;font-weight:bold;font-size:13px;width:80%;">{{content[setLang].how_title}}</div>
                              </div>
                              <template slot="popover">
                                <div>
                                  <h6 class="font-weight-bold">{{content[setLang].how_title}}</h6> 
                                  <ol type="1" class="ml-3">
                                    <li>{{content[setLang].how1}}</li><br>
                                    <li>{{content[setLang].how2}}</li><br>
                                    <li>{{content[setLang].how3}}</li><br>
                                    <li>{{content[setLang].how4}}</li>
                                  </ol>
                                </div>
                              </template>
                            </v-popover>
                          </div>

                            <div class="col-md-3 h-100 d-flex justify-content-center">
                              <v-popover offset="16" style="cursor:pointer;">
                              <div class="tooltip-target b3">
                                <img style="width:25%;float:left;margin-right:5px;" src="../assets/interpret_data_help.svg" />
                                <div style="padding-left:5px;font-weight:bold;font-size:13px;width:75%;">{{content[setLang].interpret_title}}</div>
                              </div>
                              <template slot="popover">
                                <div>
                                  <h6 class="font-weight-bold">{{content[setLang].interpret_title}}</h6> 
                                  <p>{{content[setLang].interpret1}}</p>
                                  <p>{{content[setLang].interpret2}}</p>
                                  <p>{{content[setLang].interpret3}}</p>
                                  <p>{{content[setLang].interpret4}}</p>
                                </div>
                              </template>
                            </v-popover>
                            </div>
                            
                            <div class="col-md-2 h-100 float-right">
                             <p class="clearBtn" v-on:click="reset">{{content[setLang].clear_select}}</p>
                            </div>

                          </div>
                          
                        </div>



                        <!-- Load Highcharts -->
                          <!-- pass all info to highcharts so we can load the data; if industry = 0 = overview -->
                          <h5 align=center class="mt-5">{{chartTitle}}</h5>
                          <p align=center class="font-weight-bold" style="color:#E7401C;">
                            <i class="fa fa-circle mr-1"></i>
                            {{content[setLang].sub_title}}
                          </p>
                          <div class="container" style="text-align:center;">
                            <div class="row" style="width:100%;overflow-x:scroll;display:inline-block;text-align:center;">
                          <highcharts 
                            id="chart2"
                            style="display:inline-block;overflow:auto;"
                            :sector="sectorID"
                            :industry="visibleIndustry" 
                            :disease="select_disease"
                            :occupation="select_occupation"
                            :subgroup="select_suboccupation"
                            :cancerous="isCancerous"
                            :seriesdata="chart_series"
                            :chartTitle="chartTitle"
                          >
                          </highcharts>
                          </div>
                          </div>

                        <div class="container" style="padding:20px 10px 20px 10px;">
                          <br/>
                          <div class="row">
                            <div class="col-sm-12" v-if="occupation_groups[select_occupation] != undefined && occupation_groups[select_occupation]['subgroups'].length != 0" style="background-color:#EAEAEB;padding:10px;color:black;">
                              <!-- Radio buttons for occupation subgroups from Occupation multiselect above -->
                              <h5 v-if="sectorID == 8" style="color:#0A2940;"><b>{{content[setLang].select_job2}}:</b></h5>
                              <h5 v-else style="color:#0A2940;"><b>{{content[setLang].select_job}}:</b></h5>
                              <div class="list-group d-flex flex-row flex-wrap">
                               <b-form-group class="w-50" v-for="(og, index) in occupation_groups[select_occupation]['subgroups']" :key="index">
                                   <b-form-radio v-model="select_suboccupation" primary :id="og.id" :value="og.id" :checked="og.id == select_suboccupation" v-if="setLang == 'fr'">{{og.occu_name_fr}}</b-form-radio>
                                  <b-form-radio v-model="select_suboccupation" primary :id="og.id" :value="og.id" :checked="og.id == select_suboccupation" v-else>{{og.occu_name}}</b-form-radio>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>

                      

                    <!-- SUB ACCORDION -->
                    <b-card no-body class="mb-1" style="background-color:#E7401C;" v-for="(group, index) in industry_groups" :key="index">
                      <b-card-header v-on:click="accordianSubIndustry(group.id)" header-tag="header" class="p-1" role="tab" style="background-color:#E7401C;border:none;">
                        <!-- v-b-target="'#accordion-' + group.id" -->
                        <b-button class="accTitle" v-b-toggle="'accordion-' + group.id" variant="info" style="background-color:#E7401C;border:none;font-size:2em !important;width:100%;">
                          <b v-if="setLang == 'fr' && sectorID !=8">{{group.industry_name_fr}} industrie</b>
                          <b v-else-if="setLang == 'en' && sectorID == 8">{{group.industry_name}}</b>
                          <b v-else-if="setLang == 'fr' && sectorID == 8">{{group.industry_name_fr}}</b>
                          <b v-else>{{group.industry_name}} Industry</b>

                          <span class="when-opened">
                            <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/subgroup_minus.svg" alt="overview minus" />
                          </span>
                          <span class="when-closed">
                            <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/subgroup_plus.svg" alt="overview minus" />
                          </span>

                         </b-button>
                      </b-card-header>

                      <b-collapse :id="'accordion-' + group.id" accordion="my-accordion1" role="tabpanel" style="color:white;margin:0;">
                        <b-card-body>
                          <hr style="background-color:white;margin-top:-10px;" />
                          <p style="text-align:left;">
                            {{content[setLang].result_text1}} {{dropName.toLowerCase().trim()}} {{content[setLang].result_text2}}
                          </p>
                                         
                          <!-- Multiselects for Occupations and Diseases -->              
                    <div class="container">
                      <div class="row">
                        <div class="col-md-6 float-left">
                          <Multiselect v-if="sectorID == 8" v-model="select_occupation" :options="select_occugroups" :title="content[setLang].select_occu" :typet="'occupation'" v-on:searchselect="changeOccupation" :key="occupationKey"></Multiselect>
                          <Multiselect v-else v-model="select_occupation" :options="select_occugroups" :title="content[setLang].select_occu2" :typet="'occupation'" v-on:searchselect="changeOccupation" :key="occupationKey"></Multiselect>
                        </div>
                        <div class="col-md-3 w-100" style="font-size:0.9vw;">
                          
                         <p style="font-size: 1.1vw;margin-top:40px;">Cancers<b-form-checkbox v-model="isCancerous" switch size="lg" style="float:right;height:60px;" :key="toggleCancer"><p style="font-size:1.1vw;">Non-Cancers</p></b-form-checkbox></p>
                        </div>
                        <div class="col-md-3 float-right">
                           <Multiselect v-model="select_disease" :options="diseases" :title="content[setLang].select_disease" :typet="'disease'" :cancerous="isCancerous" v-on:searchselect="changeDisease" :key="diseaseKey"></Multiselect>
                        </div>
                      </div>
                    </div>


                    <!-- Options and Highcharts section -->
                    <div style="padding:10px;background-color:white;color:black;font-size:12px;">
                      <div class="container" style="padding-bottom:50px;">

                        <!-- Row of info and select cancers/non-cancer -->
                        <div class="row">
                          <div class="col-sm-12 h-100" v-if="select_description" style="background-color:#FEF5D7;padding:10px;margin-bottom:10px;color:black;">
                              {{ select_description }}
                            </div>
                        </div>
                         <div class="row">
                           <div class="col-sm-12 h-100">
<br/>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 h-100">
                            <div style="border:0.2px solid black;padding:5px;text-align:center;">
                              <span style="margin-right:10px"><i class="fa fa-chevron-up" style="color:red;"></i> {{content[setLang].increased_risk}}</span>
                              <span><i class="fa fa-chevron-down" style="color:green;"></i> {{content[setLang].reduced_risk}}</span></div>
                            </div>

                            <div class="col-md-3 h-100 d-flex justify-content-center">
                            <v-popover offset="16" style="cursor:pointer;">
                              <div class="tooltip-target b3">
                                <img style="width:20%;float:left;margin-right:5px;" src="../assets/data_tool_help.svg" />
                                <div style="padding-left:5px;font-weight:bold;font-size:13px;width:80%;">{{content[setLang].how_title}}</div>
                              </div>
                              <template slot="popover">
                                <div>
                                  <h6 class="font-weight-bold">{{content[setLang].how_title}}</h6> 
                                  <ol type="1" class="ml-3">
                                    <li>{{content[setLang].how1}}</li><br>
                                    <li>{{content[setLang].how2}}</li><br>
                                    <li>{{content[setLang].how3}}</li><br>
                                    <li>{{content[setLang].how4}}</li>
                                  </ol>
                                </div>
                              </template>
                            </v-popover>
                          </div>

                            <div class="col-md-3 h-100 d-flex justify-content-center">
                              <v-popover offset="16" style="cursor:pointer;">
                              <div class="tooltip-target b3">
                                <img style="width:25%;float:left;margin-right:5px;" src="../assets/interpret_data_help.svg" />
                                <div style="padding-left:5px;font-weight:bold;font-size:13px;width:75%;">{{content[setLang].interpret_title}}</div>
                              </div>
                              <template slot="popover">
                                <div>
                                  <h6 class="font-weight-bold">{{content[setLang].interpret_title}}</h6> 
                                  <p>{{content[setLang].interpret1}}</p>
                                  <p>{{content[setLang].interpret2}}</p>
                                  <p>{{content[setLang].interpret3}}</p>
                                  <p>{{content[setLang].interpret4}}</p>
                                </div>
                              </template>
                            </v-popover>
                            </div>
                            
                            <div class="col-md-2 h-100 float-right">
                             <p class="clearBtn" v-on:click="reset">{{content[setLang].clear_select}}</p>
                            </div>

                          </div>
                          
                        </div>


                        <!-- Load Highcharts -->
                          <!-- pass all info to highcharts so we can load the data; if industry = 0 = overview -->
                          <h5 align=center class="mt-5">{{subIndTitle}}</h5>
                          <p align=center class="font-weight-bold" style="color:#E7401C;">
                            <i class="fa fa-circle mr-1"></i>
                            {{content[setLang].sub_title}}
                          </p>
                          <div class="container" style="text-align:center;">
                            <div class="row" style="width:100%;overflow-x:scroll;display:inline-block;text-align:center;">
                          <highchartsInd
                            id="chart3"
                            style="display:inline-block;overflow:auto;"
                            :sector="sectorID"
                            :industry="visibleIndustry"
                            :subIndustry="visibleSubIndustry"
                            :disease="select_disease"
                            :occupation="select_occupation"
                            :subgroup="select_suboccupation"
                            :cancerous="isCancerous"
                            :seriesdata="chart_series"
                            :chartTitle="subIndTitle"
                          >
                          </highchartsInd>
                          </div>
                          </div>

                        <div class="container" style="padding:20px 10px 20px 10px;">
                          <br/>
                          <div class="row">
                            <div class="col-sm-12" v-if="occupation_groups[select_occupation] != undefined && occupation_groups[select_occupation]['subgroups'].length != 0" style="background-color:#EAEAEB;padding:10px;color:black;">
                              <!-- Radio buttons for occupation subgroups from Occupation multiselect above -->
                              <h5 v-if="sectorID ==8" style="color:#0A2940;"><b>{{content[setLang].select_job2}}:</b></h5>
                              <h5 v-else style="color:#0A2940;"><b>{{content[setLang].select_job}}:</b></h5>
                              <div class="list-group d-flex flex-row flex-wrap">
                                <b-form-group class="w-50" v-for="(og, index) in occupation_groups[select_occupation]['subgroups']" :key="index">
                                  <b-form-radio v-model="select_suboccupation" primary :id="og.id" :value="og.id" :checked="og.id == select_suboccupation" v-if="setLang == 'fr'">{{og.occu_name_fr}}</b-form-radio>
                                  <b-form-radio v-model="select_suboccupation" primary :id="og.id" :value="og.id" :checked="og.id == select_suboccupation" v-else>{{og.occu_name}}</b-form-radio>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>

                   

          

                    
                   
                  </b-card-body>
                </b-collapse>

                
              </b-card>
              </div>

            </div>
          </template>
            </div><!-- END IF EXPLORE DATA -->
        </section>

        <Footer/>
    </section>
</template>

<script>
// Imports
import Vue from 'vue'
import VueHead from 'vue-head'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Feedback from '../components/feedback.vue'
import Multiselect from '../components/multiselect.vue'
import Chart from '../components/highchart.vue'
import Route from 'vue-router'
import Chart2 from '../components/highchartIndustry.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash });

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

Vue.use(VueHead, VueAxios, axios, Route)

import VTooltip from 'v-tooltip'
 
Vue.use(VTooltip)

export default {
  components: {
    Header,
    Footer,
    Multiselect,
    Feedback,
    highcharts: Chart,
    highchartsInd: Chart2
  },
  data() {
    return {
      sectorID: this.$route.query.id,
      sectorName: '',
      sectorIntro: '',
      dropText: '',
      sectors: [],
      industries: [],
      sectorImg: '',
      diseases: [],
      isCancerous: 0,
      visibleIndustry: 0,
      occupation_groups: [],
      select_occugroups: [],
      industry_groups: [],
      select_occupation: 0,
      select_disease: 0,
      select_suboccupation: 0,
      select_description: '',
      visibleSubIndustry: '',
      chart_series: [],
      occupationKey: 0,
      diseaseKey: 0,
      toggleCancer: 0,
      showExplore: false,
      select_suboccupation1: 0,
      options:{},
      content:{
        en:{
          data_tool: "../img/data_tool.svg",
          who_text: "Who is in this sector?",
          some_text: "Some Key Occupational Exposures",
          explore_text: "Explore the Data Tool",
          view_key: "view key findings",
          scroll_text: "Scroll to view industries within this sector",
          ind_overall: "Industry - Overall",
          occu_overall: "Occupations - Overall",
          result_text1: "Results are presented for relevant and sufficiently large groups in ",
          result_text2: " with more than 6 cases.",
          download_text: "Download infographic",
          select_occu: "Select or Search Occupation Group",
          select_disease: "Select Disease",
          increased_risk: "Increased risk",
          reduced_risk: "Reduced risk",
          clear_select: "Clear Selections",
          select_job: "Select Specific Job",
          sub_title: "Hover over a data point for more information",
          how_title: "How to use this Data Tool",
          how1: "View results for an overall sector, or scroll down to select an industry subgroup",
          how2: "Select or search for an occupation group from the drop down menu",
          how3: "Toggle between cancer and non-cancer diseases or select a specific disease",
          how4: "For some occupation groups, results for specific jobs can also be explored by making additional selections below the graph.",
          interpret_title: "Interpreting the Data",
          interpret1: "These figures show the percent increase or decrease in the incidence rate (or ‘risk’) of a disease diagnosis among workers in a particular group compared to all other workers in the ODSS. The incidence rate is the number of disease cases diagnosed per worker per year of follow-up. A 50% increased rate in a particular group means that the group had a 50% increased rate of that disease compared to all other workers in the ODSS.",
          interpret2: "The percent difference in risk is based on the Hazard Ratio (HR), which can be viewed by hovering over the data point. The width of the 95% Confidence Interval (CI) is based on the number of cases in each group (more cases narrows the interval).",
          interpret3: "HRs are estimated using Cox Proportional Hazards models and are adjusted for sex, year of birth, and age at start of follow-up.",
          interpret4: "Note that this measure of risk is compared to other workers in the ODSS and does not describe the probability of a disease diagnosis in a person’s lifetime.",
          explore_pm: "../img/explore_pm.svg",
          explore_mf: "../img/explore_mf.svg",
          pm_style: "cursor:pointer;width:40%;",
          mf_style: "cursor:pointer;width:42%;",
          pm_link: "../img/pm_dark.svg",
          pm_link_active: "../img/pm_light.svg",
          mf_link: "../img/mf_dark.svg",
          mf_link_active: "../img/mf_light.svg",
          pm_data_tool: "../img/pm_data_tool.svg",
          mf_data_tool: "../img/mf_data_tool.svg",
          select_occu2: "Select or Search Industry Group",
          select_job2: "Select Specific Occupation",
        },
        fr:{
          data_tool: "../img/data_tool_fr.svg",
          who_text: "Qui fait partie de ce secteur?",
          some_text: "Certaines des principales expositions professionnelles",
          explore_text: "Explorer l'outil de données",
          view_key: "voir les principaux résultats",
          scroll_text: "Faites défiler l’écran pour voir les industries de ce secteur",
          ind_overall: "industrie - Général",
          occu_overall: "Professions des services de protection - Général",
          result_text1: "Les résultats sont présentés pour des groupes pertinents et suffisamment importants du secteur ",
          result_text2: " dans lesquels il y a plus de six cas.",
          download_text: "Télécharger l'infographie",
          select_occu: "Sélectionnez or recherchez un groupe professionnel",
          select_disease: "Sélectionnez une maladie",
          increased_risk: "Risque accru",
          reduced_risk: "Risque réduit",
          clear_select: "Effacer les sélections",
          select_job: "Sélectionnez une profession spécifique",
          sub_title: "Survolez un point de données pour obtenir plus d’information",
          how_title: "Comment utiliser cet outil de données",
          how1: "Consultez les résultats pour un secteur global, ou faites défiler vers le bas pour sélectionner un sous-groupe d'industrie.",
          how2: "Recherchez ou sélectionnez un groupe professionnel dans le menu déroulant",
          how3: "Alternez entre le cancer et les autres maladies ou sélectionnez une maladie",
          how4: "Pour certains groupes professionnels, on peut également examiner les résultats liés à une profession précise en sélectionnant d’autres critères sous le graphique.",
          interpret_title: "Interprétation des données",
          interpret1: "Ces chiffres indiquent le pourcentage d’augmentation ou de diminution du taux d’incidence (ou « risque ») d’un diagnostic de maladie chez les travailleurs d’un groupe par rapport à tous les autres travailleurs dans le SSMP. Le taux d’incidence correspond au nombre de cas de maladie diagnostiquée par travailleur, par année de suivi. Un taux accru de 50 % dans un groupe signifie que le nombre de cas de la maladie concernée dans ce groupe est de 50 % supérieur comparativement à tous les autres travailleurs dans le SSMP.",
          interpret2: "La différence en pourcentage du risque est basée sur le rapport de risque, que l’on peut voir en survolant le point de données. L’ampleur de l’intervalle de confiance de 95 % est basée sur le nombre de cas dans chaque groupe (un plus grand nombre de cas resserre l’intervalle).",
          interpret3: "Les rapports de risque sont estimés à l’aide du modèle des risques proportionnels de Cox et sont rajustés en fonction du sexe, de l’année de naissance et de l’âge au début du suivi.",
          interpret4: "Veuillez noter que cette mesure du risque est comparée à celle des autres travailleurs dans le SSMP et ne décrit pas la probabilité d’un diagnostic de la maladie au cours de la vie d’une personne.",
          explore_pm: "../img/explore_pm_fr.svg",
          explore_mf: "../img/explore_mf_fr.svg",
          pm_style: "cursor:pointer;width:60%;",
          mf_style: "cursor:pointer;width:65%;",
          pm_link: "../img/pm_dark_fr.svg",
          pm_link_active: "../img/pm_light_fr.svg",
          mf_link: "../img/mf_dark_fr.svg",
          mf_link_active: "../img/mf_light_fr.svg",
          pm_data_tool: "../img/pm_data_tool_fr.svg",
          mf_data_tool: "../img/mf_data_tool_fr.svg",
          select_occu2: "Sélectionnez or recherchez un groupe d'industrie",
          select_job2: "Sélectionnez une profession spécifique",
        }
      },
      useDataToolText: '<h5>How to use this Data Tool</h5> <ol><li>View results for an overall sector, or scroll down to select an industry subgroup</li><li>Select or search for an occupation group from the drop down menu</li><li>Toggle between cancer and non-cancer diseases or select a specific disease</li><li>For some occupation groups, results for specific jobs can also be explored by making additional selections below the graph.</li></ol>'
    }
  },
  head: {
    title:{
      inner:' Sectors | Ontario Occupational Disease Statistics'
    }
  },
  mounted: function() {
     this.getSectors();
     this.getIndustries();
     this.getDiseases();
     this.getOccupationGroups();
     this.getChartData();
  },
  computed: {
    setLang : function () {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return lang;
    },
    locpath: function() {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      if (this.$route.name == "Sector") {
        return '?id=' + this.$route.query.id;
      } else {
        return '?locale='+lang;
      }
    },
    dropName : function () {
      let path1 = this.$route.query.id;
      var lang = this.setLang;
      if (this.sectors[path1] == undefined) {
        return this.dropText;
      } else {
        if (lang === 'fr'){
          return this.sectors[path1].sector_name_fr;
        } else {
          return this.sectors[path1].sector_name;
        }
      }
    },
    chartTitle: function () {
      var ctitle = "";
      var lang = this.setLang;
      var occupation = parseInt(this.select_occupation);
      var sub_occupation = parseInt(this.select_suboccupation);

      if (lang == 'fr') {
        if (occupation != 0 && sub_occupation == 0) {
          ctitle = this.occupation_groups[occupation].occu_name_fr;
        } else if (occupation != 0 && sub_occupation != 0) {
          ctitle = this.occupation_groups[occupation].subgroups[sub_occupation].occu_name_fr;
        } else if (this.visibleIndustry != 0 && occupation == 0){
          ctitle = this.industries[this.visibleIndustry].industry_name_fr;
        } else {
          if (this.sectorID == 8) {
            ctitle = this.content[lang].occu_overall;
          }else{
            ctitle = this.dropName + " industrie général";
          }
        }
      } else {
        if (occupation != 0 && sub_occupation == 0) {
          ctitle = this.occupation_groups[occupation].occu_name;
        } else if (occupation != 0 && sub_occupation != 0) {
          ctitle = this.occupation_groups[occupation].subgroups[sub_occupation].occu_name;
        } else if (this.visibleIndustry != 0 && occupation == 0){
          ctitle = this.industries[this.visibleIndustry].industry_name;
        } else {
          if (this.sectorID == 8) {
            ctitle = this.dropName + " " + this.content[lang].occu_overall;
          }else{
            ctitle = this.dropName + " industry overall";
          }
        }
      }
      // var cancerText = (this.isCancerous == 0) ? "Cancer" : "Non-cancer";
      // if (this.select_occupation != 0 && this.select_suboccupation == 0) {
      //   ctitle = cancerText + " results for " + this.occupation_groups[this.select_occupation].occu_name;
      // } else if (this.select_occupation != 0 && this.select_suboccupation == 0 && this.select_suboccupation != 0) {
      //   ctitle = cancerText + " results for " + this.occupation_groups[this.select_occupation].subgroups[this.select_suboccupation].occu_name;
      // } else if (this.visibleIndustry != 0 && this.select_occupation == 0){
      //   ctitle = cancerText + " results for the " + this.industries[this.visibleIndustry].industry_name + " industry overall";
      // } else {
      //   ctitle = cancerText + " results for the " + this.dropName + " industry overall";
      // }
      return ctitle;
    },
    subIndTitle: function () {
      let g = this.industry_groups;
      let i = parseInt(this.visibleSubIndustry);
      if (i > 0){
        return g[i].industry_name + " industry overall";
      }else{
        return "";
      }
    },
    dropDescription:function () {
      let path1 = this.$route.query.id;
      var lang = this.setLang;
      if (this.sectors[path1] == undefined) {
        return this.dropText;
      } else {
        if (lang === 'fr'){
          return this.sectors[path1].description_fr;
        } else {
          return this.sectors[path1].description;
        }
      }
    },
    keyExposures:function () {
      let path1 = this.$route.query.id;
      var lang = this.setLang;
      if (this.sectors[path1] == undefined) {
        return this.dropText;
      } else {
        if (lang === 'fr'){
          return this.sectors[path1].key_exposures_fr;
        } else {
          return this.sectors[path1].key_exposures;
        }
      }
    },
    filteredIndustry: function() {
      let filterIndustries = [];
      let indd = this.industries;
      this.lodash.forEach(indd, function(ind){
        if (ind.minor_id == 0) {
          filterIndustries[ind.id] = ind;
        }
        if (ind.minor_id > 0) {
          filterIndustries[ind.id]['subgroups'] = ind;
        }
      });
      return filterIndustries;
    }
  },
  methods: {
    reset() {
      // Reset selections
      this.isCancerous = 0;
      this.select_occupation = 0;
      this.select_disease = '';
      this.select_suboccupation = '';
      this.select_description = '';
      this.forceRerender();
    },
    forceRerender() {
      this.getOccupationGroups();
      this.occupationKey += 1;
      this.diseaseKey += 1;
      this.toggleCancer += 1;
    },
    forceRerenderDisease() {
      this.diseaseKey += 1;
    },
    showPrimary() {
      this.showExplore = true;
      this.sectorID = 4;
    },
    getSectors() {
        var urloc = (window.location.origin == "http://localhost:8080") ? "https://odss.ccohs.ca" : window.location.origin;
        axios.get(urloc+"/php/data.php?action=getSectors").then(response => {
            this.sectors = response.data;
            let path1 = this.$route.query.id;
            if (path1) {
                  this.dropText = this.sectors[path1].sector_name;
            } else {
                this.dropText = "CHOOSE";
            }
        });
    },
    getSectorIcon() {
      var images = require.context('../assets/', false, /\.svg$/);
      let path1 = this.$route.query.id;
      if (this.sectors[path1] != undefined) {
        this.sectorImg = this.sectors[path1].sector_icon + ".svg";
        return images('./' + this.sectorImg);
      }
    },
    getSectorInfrographic() {
      var images = require.context('../assets/', false, /\.svg$/);
      let path1 = this.$route.query.id;
      if (this.sectors[path1] != undefined) {
        if (this.setLang == 'fr'){
          this.sectorImg = this.dropName + "_infrographic_fr.svg";
        }else {
          this.sectorImg = this.dropName + "_infrographic.svg";
        }
        return images('./' + this.sectorImg);
      }
    },
    getSectorData() {
      // get all data for this sector
      // array with basic sector info, sector data, industries and data, subgroups and data
    },
    getIndustries() {
        let sectors = this.sectors;
        let sid = this.$route.query.id;
        let sc = (sectors[sid] != undefined) ? sectors[sid].sector_code : 1;
        var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
        axios.get(urloc+"/php/data.php?action=getIndustry&sid="+sid+"&sc="+sc).then(response => {
            this.industries = response.data;
        });
    },
    getDiseases() {
      let s = this.$route.query.id;
      let i = parseInt(this.visibleIndustry);
      let o = parseInt(this.select_occupation);
      let g = parseInt(this.select_suboccupation);
      var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
        axios.get(urloc+"/php/data.php?action=getDiseases&s="+s+"&i="+i+"&o="+o+"&g="+g).then(response => {
            this.lodash.orderBy(response.data.diseases, 'disease_name', 'asc');
            this.diseases = response.data.diseases;
        });
    },
    getIndustryGroup() {
        //let sid = this.$route.query.id;
        let inid = this.visibleIndustry; // When an accordian is open, use this to get the subgroups
        if (inid > 0){
          var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
          axios.get(urloc+"/php/data.php?action=getIndustryGroup&inid="+inid).then(response => {
              this.industry_groups = response.data;
          });
        }
    },
    getOccupationGroups() {
      let sid = this.$route.query.id;
      let indid = this.visibleIndustry;
      let cancerous = this.isCancerous;
      let name = '';
      let self = this;
      if(parseInt(self.select_occupation) > 0){
        name = this.occupation_groups[this.select_occupation].occu_name;
      }
      var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
      axios.get(urloc+"/php/data.php?action=getOccupationGroups&sid="+sid+"&indid="+indid+"&can="+cancerous).then(response => {
          self.occupation_groups = response.data;
          self.select_occugroups = response.data;

          if(parseInt(this.select_occupation) > 0){
            this.lodash.forEach(this.occupation_groups, function(og) {
              if (name === og.occu_name) {
                self.select_occupation = og.id;
                return;
              }
            });
          }
      });
      
    },
    accordianIndustry(id) {
      this.visibleIndustry = id;
      this.getOccupationGroups();
      if (id != 0) {
        this.getIndustryGroup(id);
      }
    },
    accordianSubIndustry(id) {
      this.visibleSubIndustry = id;
    },
    changeOccupation(selected, query) {
      console.log(query);
      console.log(selected);
      let self = this;
      let searchgroups = [];
      if (query != '') {
        lodash.forEach(self.occupation_groups, function(i){
          if (i.subgroups !== undefined) {
            lodash.forEach(i.subgroups, function(g){
              if (lodash.includes(g.occu_name.toLowerCase(), query.toLowerCase())) {
                searchgroups.push(g);
              }
            });
          }
          if (lodash.includes(i.occu_name, query)) {
            searchgroups.push(i);
          }
        });
        self.select_occugroups = searchgroups;
      }

      if (this.occupation_groups[selected] != undefined && selected > 0) {
        self.select_occupation = selected;
        self.select_suboccupation = 0;
      } else if (this.occupation_groups[selected] == undefined && selected > 0) {
        lodash.forEach(self.occupation_groups, function(i){
          if (i.subgroups !== undefined) {
            lodash.forEach(i.subgroups, function(g){
              if (g.id == selected) {
                self.select_occupation = i.id;
                self.select_suboccupation = g.id;
                return;
              }
            });
          }
        });
      } else {
        this.select_occupation = 0;
        this.select_suboccupation = 0;
      }
    },
    changeDisease(selected, query) {
      this.getOccupationGroups();
      console.log(query);
      this.select_disease = selected;
    },
    searchSelect(query) {
      console.log(query);
    },
    getChartData() {
      //let cs = this.chart_series;
      let series = [];
      let s = this.$route.query.id;
      let i = parseInt(this.visibleIndustry);
      let d = parseInt(this.select_disease);
      let o = parseInt(this.select_occupation);
      let g = parseInt(this.select_suboccupation);
      let c = parseInt(this.isCancerous);
      var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
      axios.get(urloc+"/php/data.php?action=getChartData&s="+s+"&i="+i+"&d="+d+"&o="+o+"&g="+g+"&c="+c).then(response => {
          //this.chart_series = response.data;
          
          this.lodash.forEach(response.data, function(d){
            let image = '/img/' + d.icon_name + '.svg';
            let hr = parseFloat(d.hazard_ratio);
            hr = (hr < 1) ? -Math.abs((( 1 - (hr)) * 100).toFixed(0)) : (((hr) - 1)*100).toFixed(0);
            let seriesData = {
              name: d.disease_name,
              low: parseFloat(hr),
              hazr: d.hazard_ratio,
              icon: image,
              ci: d.confidence_interval,
              workers: d.total_workers,
              cases: d.total_cases
            };
            series.push(seriesData);
          });
        this.chart_series = series;
      });
      
    }
  },
  watch: {
    dropName: function() {
      this.sectorID = this.$route.query.id;
      this.getSectorIcon();
      this.showExplore = false;
    },
    visibleIndustry: function() {
      this.select_occupation = 0;
      this.select_suboccupation = 0;
      this.getOccupationGroups();
      this.getDiseases();
      this.getChartData();
      if (this.visibleIndustry != 0) {
        this.select_description = (this.setLang == 'fr') ? this.industries[this.visibleIndustry].description_fr : this.industries[this.visibleIndustry].description;
      } else {
        this.select_description = '';
      }
    },
    select_suboccupation: function() {
      this.getChartData();
      this.getDiseases();
      if (this.select_occupation != 0 && this.select_suboccupation != 0) {
        this.select_description = (this.setLang == 'fr') ? this.occupation_groups[this.select_occupation].subgroups[this.select_suboccupation].description_fr : this.occupation_groups[this.select_occupation].subgroups[this.select_suboccupation].description;
      } else {
        this.select_description = '';
      }
    },
     select_occupation: function() {
      this.getChartData();
      this.getDiseases();
      if (this.select_occupation != 0) {
        this.select_description = (this.setLang == 'fr') ? this.occupation_groups[this.select_occupation].description_fr : this.occupation_groups[this.select_occupation].description;
      } else {
        this.select_description = '';
      }
    },
    sectorID: function() {
      this.select_occupation = 0;
      this.select_suboccupation = 0;
      this.getSectors();
      this.getIndustries();
      this.getOccupationGroups();
      this.getIndustryGroup();
      this.getDiseases();
      this.getChartData();
    },
    select_disease: function() {
      this.getChartData();
    },
    isCancerous: function(){
      if (this.isCancerous == true) {
        this.isCancerous = 1;
        this.changeDisease(0);
        this.forceRerenderDisease();
      } else {
        this.isCancerous = 0;
        this.changeDisease(0);
        this.forceRerenderDisease();
      }
      this.select_suboccupation = 0;
      this.getOccupationGroups();
      this.getDiseases();
      this.getChartData();
    },
    chart_series: function(){
      this.getOccupationGroups();
      //this.getDiseases();
    },
    setLang: function(){
      console.log("");
      if (this.select_suboccupation > 0) {
        this.select_description = (this.setLang == 'fr') ? this.occupation_groups[this.select_occupation].subgroups[this.select_suboccupation].description_fr : this.occupation_groups[this.select_occupation].subgroups[this.select_suboccupation].description;
      } else if(this.select_suboccupation == 0 && this.select_occupation > 0) {
        this.select_description = (this.setLang == 'fr') ? this.occupation_groups[this.select_occupation].description_fr : this.occupation_groups[this.select_occupation].description;
      } else if (this.sub_occupation == 0 && this.select_suboccupation == 0 && this.visibleIndustry > 0) {
        this.select_description = (this.setLang == 'fr') ? this.industries[this.visibleIndustry].description_fr : this.industries[this.visibleIndustry].description;
      } else {
        this.select_description = '';
      }
    }
  }
}
</script>


<style type="text/css">
@import "~font-awesome/css/font-awesome.css";
hr {
  margin-top:-10px;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  color: #2c3e50;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

h1, h2 {
  font-weight: normal;
}

h2 {
  color:#E7401C;
}




.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  font-size:14px;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid black;
  background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  left: 8px;
  content: " ";
  display: block;
  background: orange;
}

.clearBtn {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight:bold;
  background-color: #E7401C;
  padding:8px;
  width:100%;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

main {
  margin:0 auto;
  padding:0;
  width:70%;
}

.video {
  width:100%;
  height:28em;
  border:solid 1px black;
  margin:0 auto;
  padding:0;
}

.introText {
  margin-top:3%;
  font-size:1.5em;
  text-align:left;
}

#sectors { 
  border: 1px solid #E7401C;
  border-radius: 120px;
  margin-top:3%;
}

html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, section, time, mark, audio, video {
    margin: 0 auto;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;

}

/** accordion styles */
.btn-info.focus, .btn-info:focus, .btn:focus, .info:focus .btn {
  box-shadow:none !important;
  background-color:#0A2940;
  border:none;
}

.btn {
  background-color:#0A2940;
  border:none;
  text-align:left !important;
}
.accTitle {text-align: left};


.tooltip.popover .popover-inner {
background: #f9f9f9;
color: black;
padding: 24px;
border-radius: 5px;
box-shadow: 0 5px 30px rgba(0, 0, 0, .1);
}
.tooltip.popover .popover-arrow {
border-color: #E7401C;
}


.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
  border-color: #E7401C;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
  text-align:left;
  min-width:400px;
  font-size:12px;
  border: 2px solid #E7401C;
}

.tooltip.popover .popover-arrow {
  border-color: #E7401C;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
 
</style>
