
<template>
  <section>

    <!-- Include Header -->
    <Header />

    <!-- main section -->
    <section class="main">

      <!-- Include feedback link -->
      <Feedback />

      <!-- Sector Title -->
      <section class="topBar" style="background-color:#1B355E;margin-top:0;color:white;text-shadow: 0px 3px 6px #00000091;">
          <h1 style="text-align:left;margin-left:10%;font-size:4vw;padding:10px 0px 10px 0px;">{{ dropName }}</h1>
      </section>

      <!-- Top part of the page -- Description, Icon and Key Results -->
      <div id="topLogos">
        <div class="container">
          <div class="row">
            <div class="col-sm-9">
              <p style="text-align:left;color:#3C4148;font-size:1.1em" v-html="dropDescription"></p>
            </div>
            <div class="col-sm-3 text-center" style="font-size:12px;">
              <img class="img-fluid" :src="getExposureIcon()" alt="Exposure Icon" />
              <div class="mt-3 pl-3" style="border-left:1px solid #E7401C;font-size:14px;">
                <p class="font-weight-bold">{{content[setLang].key_health}}</p>
                <div v-html="keyHealth"></div>
              </div>
            </div>
          </div>
        </div>


        <!-- Welding Fumes -->
        <div v-if="exposureID == 6">
          <p>
            <router-link :to="'/exposure?id=9&locale=' + setLang" class="topNavLink" v-on:click="showExplore = true">
              <img class="img-fluid" :src="content[setLang].explore_arc" :style="content[setLang].arc_style" v-if="!showExplore" alt="Show Data Tool" />
            </router-link>
          </p>
          <p>
          <router-link :to="'/exposure?id=7&locale=' + setLang" class="topNavLink" v-on:click="showExplore = true">
            <img class="img-fluid" :src="content[setLang].explore_gas" :style="content[setLang].gas_style" v-if="!showExplore" alt="Show Data Tool" />
          </router-link>
          </p>
          <router-link :to="'/exposure?id=8&locale=' + setLang" class="topNavLink" v-on:click="showExplore = true">
            <img class="img-fluid" :src="content[setLang].explore_soldering" :style="content[setLang].soldering_style" v-if="!showExplore" alt="Show Data Tool" />
          </router-link>
        </div>
        <div v-else-if="exposureID == 9"> 
          <div class="container">
            <div class="row">
                <div class="col-lg-4 text-center">
                  <router-link :to="'/exposure?id=9&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].arc_link_active" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
                <div class="col-lg-4 text-center">
                  <router-link :to="'/exposure?id=7&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].gas_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
                <div class="col-lg-4 text-center">
                  <router-link :to="'/exposure?id=8&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].soldering_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
            </div>
        </div>
        </div>
        <div v-else-if="exposureID == 7">
          <div class="container">
            <div class="row">
                <div class="col-lg-4 text-center">
                  <router-link :to="'/exposure?id=9&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].arc_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
                <div class="col-lg-4 text-center">
                  <router-link :to="'/exposure?id=7&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].gas_link_active" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
                <div class="col-lg-4 text-center">
                  <router-link :to="'/exposure?id=8&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].soldering_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
            </div>
          </div>
        </div>
        <div v-else-if="exposureID == 8">
          <div class="container">
            <div class="row">
                <div class="col-lg-4 text-center">
                  <router-link :to="'/exposure?id=9&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].arc_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
                <div class="col-lg-4 text-center">
                  <router-link :to="'/exposure?id=7&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].gas_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
                <div class="col-lg-4 text-center">
                  <router-link :to="'/exposure?id=8&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].soldering_link_active" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
            </div>
          </div>
        </div>
        
        <!-- Flour and Grain Dust -->
        <div v-else-if="exposureID == 14">
          <p>
            <router-link :to="'/exposure?id=12&locale=' + setLang" class="topNavLink" v-on:click="showExplore = true">
              <img class="img-fluid" :src="content[setLang].explore_flour" :style="content[setLang].flour_style" v-if="!showExplore" alt="Show Data Tool" />
            </router-link>
          </p>
          <p>
          <router-link :to="'/exposure?id=13&locale=' + setLang" class="topNavLink" v-on:click="showExplore = true">
            <img class="img-fluid" :src="content[setLang].explore_grain" :style="content[setLang].grain_style" v-if="!showExplore" alt="Show Data Tool" />
          </router-link>
          </p>
        </div>
        <div v-else-if="exposureID == 12"> 
          <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center">
                  <router-link :to="'/exposure?id=12&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].flour_link_active" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
                <div class="col-lg-6 text-center">
                  <router-link :to="'/exposure?id=13&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].grain_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
            </div>
        </div>
        </div>
        <div v-else-if="exposureID == 13">
          <div class="container">
            <div class="row">
                <div class="col-lg-6 text-center">
                  <router-link :to="'/exposure?id=12&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].flour_link" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
                <div class="col-lg-6 text-center">
                  <router-link :to="'/exposure?id=13&locale=' + setLang" v-on:click="showExplore = true">
                    <img class="img-fluid" :src="content[setLang].grain_link_active" v-if="!showExplore" style="cursor:pointer;" alt="Show Data Tool" />
                  </router-link>
                </div>
            </div>
          </div>
        </div>
        
        <div v-else>
          <div>
            <img class="img-fluid" :src="content[setLang].data_tool" style="width:250px;float:left;cursor:pointer;" v-if="!showExplore" v-on:click="showExplore = true" alt="Show Data Tool"/>
            <p v-if="!showExplore"><br/></p>
          </div>
        </div>
      </div> 
      <!-- End top part of the page -->

          
      <!-- Show infographic on screen if showExplore is false -->
      <div id="glanceData" v-if="!showExplore && exposureID != 7  && exposureID != 8  && exposureID != 9 && exposureID != 12  && exposureID != 13">

        <!-- if language is set to french -->
          <div v-if="setLang == 'fr'">
            <div style="border:1px solid #E7401C;;text-align:center;">
            <img class="img-fluid" style="width:90%;margin-top:2rem;margin-bottom:3rem;" :src="'/img/'+(dropText.toLowerCase()).replace(/ /g, '')+'_infographic_fr.svg'" :alt="(dropText.toLowerCase()).replace(/ /g, '') + ' infographic'" />
            </div>
            <div style="width:20%;margin-top:-25px;margin-left:70%;background:white;">
            <a :href="'/img/'+(dropText.toLowerCase()).replace(/ /g, '')+'_infographic_fr.svg'" :download="'ODSS_'+(dropText.toLowerCase()).replace(/ /g, '')+'_exposure_infographic_fr.svg'">
                <p class="downloadBtn">{{content[setLang].download_text}}</p>
            </a>
            </div>
          </div>
          <!-- if language is not french -->
          <div v-else>
            <div style="border:1px solid #E7401C;;text-align:center;">
            <img class="img-fluid" style="width:90%;margin-top:2rem;margin-bottom:3rem;" :src="'/img/'+(dropText.toLowerCase()).replace(/ /g, '')+'_infographic.svg'" :alt="(dropText.toLowerCase()).replace(/ /g, '') + ' infographic'" />
            </div>
            <div style="width:20%;margin-top:-25px;margin-left:70%;background:white;">
            <a :href="'/img/'+(dropText.toLowerCase()).replace(/ /g, '')+'_infographic.svg'" :download="'ODSS_'+(dropText.toLowerCase()).replace(/ /g, '')+'_exposure_infographic.svg'">
                <p class="downloadBtn">{{content[setLang].download_text}}</p> 
            </a>
            </div>
          </div>
          <br/><br/><br/>
      </div>
      <!-- End show infographic -->


      <!-- Data tool start -->
      <div id="exploreData" v-if="showExplore || (exposureID == 7 || exposureID == 8 || exposureID == 9 || exposureID == 12 || exposureID == 13 )">

        <!-- Show "view key findings" link to switch from data tool to infographic -->
        
        <div id="topLogos">
          <div class="container">
            <div class="row">
              <div class="col-md-12" v-if="exposureID == 7 || exposureID == 8 || exposureID == 9">
                <router-link :to="'/exposure?id=6&locale=' + setLang"><p style="cursor:pointer;text-align:right;" class="viewKeyFindings">{{content[setLang].view_key}}</p></router-link>
              </div>
              <div class="col-md-12" v-else-if="exposureID == 12 || exposureID == 13">
                <router-link :to="'/exposure?id=14&locale=' + setLang"><p style="cursor:pointer;text-align:right;" class="viewKeyFindings">{{content[setLang].view_key}}</p></router-link>
              </div>
              <div class="col-md-12" v-else>
                <a v-on:click="showExplore = !showExplore"><p style="cursor:pointer;text-align:right;" class="viewKeyFindings">{{content[setLang].view_key}}</p></a>
              </div>
            </div>
          </div>
        </div>


        <!-- Data tool start -->
        <template>
          <div role="tablist" style="margin-left:14%;margin-right:14%;">
            
            <!-- Overview Sector -->
            <b-card no-body class="mb-1" style="background-color:#0A2940;padding:10px;">
              <div class="container">
                <div class="row">
                  <div class="col-md-6" v-if="showIndustry==true">
                    <!-- By Industry -->
                    <h2 class="dataToolExpoActive" v-on:click="toggle_dataset(0)">{{content[setLang].by_industry}}</h2>
                  </div>
                  <div class="col-md-6" v-else>
                    <!-- By Industry -->
                    <h2 class="dataToolExpo" v-on:click="toggle_dataset(0)">{{content[setLang].by_industry}}</h2>
                  </div>
                  <div class="col-md-6" v-if="showOccupation==true">
                    <!-- By Occupation -->
                    <h2 class="dataToolExpoActive" v-on:click="toggle_dataset(1)">{{content[setLang].by_occupation}}</h2>
                  </div>
                  <div class="col-md-6" v-else>
                    <!-- By Occupation -->
                    <h2 class="dataToolExpo" v-on:click="toggle_dataset(1)">{{content[setLang].by_occupation}}</h2>
                  </div>
                </div>
              </div>


              <!-- Overview Header -->
                <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                </b-card-header>

                <!-- Collapsed Content for Overview -->
                <b-collapse id="accordion-0" visible accordion="my-accordion" role="tabpanel" style="color:white;margin:0;">
                  <b-card-body>
                    {{content[setLang].results_text}}

                    <!-- If by industry -->
                    <div v-if="showIndustry == 1">

                      <!-- Multiselects for Industries and Diseases -->              
                      <div class="container" style="">
                        <div class="row">
                          <div class="col-md-6 float-left">
                            <Multiselect v-model="select_industry" :options="industries" :title="content[setLang].select_ind" :typet="'industry'" :key="occupationKey" v-on:searchselect="changeIndustry"></Multiselect>
                          </div>
                          <div class="col-md-3"></div>
                          <div class="col-md-3 float-right">
                            <Multiselect v-model="select_disease" :options="diseaseList" :diseases="diseases" :title="content[setLang].select_disease" :typet="'diseaseExposure'" v-on:searchselect="changeDisease" :key="diseaseKey"></Multiselect>
                          </div>
                        </div>
                      </div>

                      <!-- Top Info Icons and Clear Selections -->
                      <div style="padding:10px;background-color:white;color:black;font-size:12px;margin-top:20px;">
                        <div class="container" style="">
                          <div class="row">
                            <div class="col-sm-12 h-100" v-if="select_description" style="background-color:#FEF5D7;margin-bottom:10px;padding:10px;color:black;">
                              {{ select_description }}
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 h-100"><br/></div>
                          </div>

                          <div class="row">
                            
                            <div class="col-md-3 h-100 d-flex">
                              <v-popover offset="16" style="cursor:pointer;">
                                <div class="tooltip-target b3">
                                  <img style="width:25%;float:left;margin-right:5px;" src="../assets/data_tool_help.svg" />
                                  <div style="padding-left:5px;font-weight:bold;font-size:13px;width:100%;">{{content[setLang].how_title}}</div>
                                </div>
                                <template slot="popover">
                                  <div>
                                    <h6 class="font-weight-bold">{{content[setLang].how_title}}</h6> 
                                    <ol type="1" class="ml-3">
                                      <li>{{content[setLang].how1_industry}}</li><br>
                                      <li>{{content[setLang].how2_industry}}</li><br>
                                      <li>{{content[setLang].how3_industry}}</li><br>
                                      <li>{{content[setLang].how4_industry}}</li>
                                    </ol>
                                  </div>
                                </template>
                              </v-popover>
                            </div>


                            <div class="col-md-3 h-100 d-flex">
                              <v-popover offset="16" style="cursor:pointer;">
                                <div class="tooltip-target b3">
                                  <img style="float:left;margin-right:5px;" src="../assets/interpret_data_help.svg" />
                                  <div style="padding-left:5px;font-weight:bold;font-size:13px;width:100%;">{{content[setLang].interpret_title}}</div>
                                </div>
                                <template slot="popover">
                                  <div>
                                    <h6 class="font-weight-bold">{{content[setLang].interpret_title}}</h6> 
                                    <p>{{content[setLang].interpret1}}</p>
                                    <p>{{content[setLang].interpret2}}</p>
                                    <p>{{content[setLang].interpret3}}</p>
                                    <p>{{content[setLang].interpret4}}</p>
                                    <p>{{content[setLang].interpret5}}</p>
                                    <p>{{content[setLang].interpret6}}</p>
                                  </div>
                                </template>
                              </v-popover>
                            </div>

                            <div class="col-md-3 h-100 d-flex justify-content-center">
                            </div>
                              
                            <div class="col-md-3 h-100 float-right">
                              <p class="clearBtn" v-on:click="reset">{{content[setLang].clear_select}}</p>
                            </div>

                          </div>
                        </div>


                        <!-- Begin Chart -->
                        <h4 align=center class="mt-4">{{ chartTitle }}</h4>
                        <p align=center class="font-weight-bold" style="color:#E7401C;">
                          <i class="fa fa-circle mr-1"></i>
                          {{content[setLang].sub_title}}
                        </p>

                        <Expotable 
                          :industries="industries"
                          :diseases="diseases"
                          :industry_groups="industry_groups"
                          :industry_subgroups="industry_subgroups"
                          :industry_intensity="industry_intensity"
                          :industry_data="industry_data"
                          :select_industry="select_industry"
                          :select_disease="select_disease"
                          :grouped="grouped_industry"
                          :tableType="'industry'">
                        </Expotable>

                      </div>

                                        
                    </div>

                    <div v-else>

                       <!-- Multiselects for Industries and Diseases -->              
                      <div class="container" style="">
                        <div class="row">
                          <div class="col-md-6 float-left">
                            <Multiselect v-model="select_occupation" :options="occupations" :title="content[setLang].select_occu" :typet="'occuExposure'" :key="occupationKey"  v-on:searchselect="changeOccupation"></Multiselect>
                          </div>
                          <div class="col-md-3"></div>
                          <div class="col-md-3 float-right">
                            <Multiselect v-model="select_disease" :options="diseaseList" :diseases="diseases" :title="content[setLang].select_disease" :typet="'diseaseExposure'" v-on:searchselect="changeDisease" :key="diseaseKey"></Multiselect>
                          </div>
                        </div>
                      </div>

                      <!-- Top Info Icons and Clear Selections -->
                      <div style="padding:10px;background-color:white;color:black;font-size:12px;margin-top:20px;">
                        <div class="container" style="">
                          <div class="row">
                            <div class="col-sm-12 h-100" v-if="select_description" style="background-color:#FEF5D7;margin-bottom:10px;padding:10px;color:black;">
                              {{ select_description }}
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12 h-100"><br/></div>
                          </div>

                          <div class="row">
                            <div class="col-md-3 h-100 d-flex justify-content-center">
                              <v-popover offset="16" style="cursor:pointer;">
                                <div class="tooltip-target b3">
                                  <img style="width:25%;float:left;margin-right:5px;" src="../assets/data_tool_help.svg" />
                                  <div style="padding-left:5px;font-weight:bold;font-size:13px;width:80%;">{{content[setLang].how_title}}</div>
                                </div>
                                <template slot="popover">
                                  <div>
                                    <h6 class="font-weight-bold">{{content[setLang].how_title}}</h6> 
                                    <ol type="1" class="ml-3">
                                      <li>{{content[setLang].how1_occupation}}</li><br>
                                      <li>{{content[setLang].how2_occupation}}</li><br>
                                      <li>{{content[setLang].how3_occupation}}</li><br>
                                      <li>{{content[setLang].how4_occupation}}</li>
                                    </ol>
                                  </div>
                                </template>
                              </v-popover>
                            </div>


                            <div class="col-md-3 h-100 d-flex justify-content-center">
                              <v-popover offset="16" style="cursor:pointer;">
                                <div class="tooltip-target b3">
                                  <img style="float:left;margin-right:5px;" src="../assets/interpret_data_help.svg" />
                                  <div style="padding-left:5px;font-weight:bold;font-size:13px;width:100%;">{{content[setLang].interpret_title}}</div>
                                </div>
                                <template slot="popover">
                                  <div>
                                    <h6 class="font-weight-bold">{{content[setLang].interpret_title}}</h6> 
                                    <p>{{content[setLang].interpret1}}</p>
                                    <p>{{content[setLang].interpret2}}</p>
                                    <p>{{content[setLang].interpret3}}</p>
                                    <p>{{content[setLang].interpret4}}</p>
                                    <p>{{content[setLang].interpret5}}</p>
                                    <p>{{content[setLang].interpret6}}</p>
                                  </div>
                                </template>
                              </v-popover>
                            </div>

                            <div class="col-md-3 h-100 d-flex justify-content-center">
                            </div>
                              
                            <div class="col-md-3 h-100 float-right">
                              <p class="clearBtn" v-on:click="reset">{{content[setLang].clear_select}}</p>
                            </div>

                          </div>
                        </div>


                        <!-- Begin Chart -->
                        <h4 align=center class="mt-4">{{ chartTitle }}</h4>
                        <p align=center class="font-weight-bold" style="color:#E7401C;">
                          <i class="fa fa-circle mr-1"></i>
                          {{content[setLang].sub_title}}
                        </p>

                        <Expotable 
                          :occupations="occupations"
                          :diseases="diseases"
                          :occu_groups="occupation_groups"
                          :occu_subgroups="occupation_subgroups"
                          :occu_group_data="occu_group_data"
                          :occu_sub_data="occu_sub_data"
                          :occu_group_intensity="occu_group_intensity"
                          :occu_sub_intensity="occu_sub_intensity"
                          :select_occupation="select_occupation"
                          :select_disease="select_disease"
                          :grouped="grouped_occupation"
                          :tableType="'occupation'">
                        </Expotable>
                      </div>

                    </div>

                    
                  </b-card-body>
                </b-collapse>
              
            </b-card>
          </div>
        </template>

        
      </div><!-- END IF EXPLORE DATA -->

    </section>

    <!-- Include footer -->
    <Footer/>
  </section>
</template>

<script>


// Imports
import Vue from 'vue'
import VueHead from 'vue-head'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Multiselect from '../components/multiselect.vue'
import Feedback from '../components/feedback.vue'
import Expotable from '../components/expoTable.vue'
import Route from 'vue-router'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

//import VueLodash from 'vue-lodash'
// import lodash from 'lodash'
// Vue.use(VueLodash, { lodash: lodash });

Vue.use(VueHead, VueAxios, axios, Route)

export default {
  components: {
    Header,
    Footer,
    Feedback,
    Multiselect,
    Expotable
  },
  data() {
    return {
      exposureID: this.$route.query.id,
      sectorName: '',
      sectorIntro: '',
      dropText: '',
      sectors: [],
      exposures:[],
      industries: [],
      industry_groups: [],
      industry_subgroups:[],
      industry_intensity:[],
      industry_data:[],
      grouped_industry: [],
      sectorImg: '',
      diseases: [],
      toggleDataSet:0,
      showIndustry: true,
      showOccupation: false,
      visibleIndustry: 0,
      occupations: [],
      occupation_groups: [],
      occupation_subgroups: [],
      occu_group_intensity: [],
      occu_sub_intensity: [],
      occu_group_data: [],
      occu_sub_data: [],
      grouped_occupation: [],
      select_occugroups: [],
      select_occupation: 0,
      select_disease: 0,
      select_industry:0,
      select_suboccupation: 0,
      select_description: '',
      visibleSubIndustry: '',
      occupationKey: 0,
      diseaseKey: 0,
      showExplore: false,
      select_suboccupation1: 0,
      row1:false,
      row2:false,
      content:{
        en:{
          key_health: "Key Health Effects in the Occupational Disease Surveilance System",
          data_tool: "../img/data_tool.svg",
          view_key: "view key findings",
          download_text: "Download infographic",
          by_industry: "By Industry",
          by_occupation: "By Occupation",
          results_text: "Results are presented for relevant and sufficiently large groups that reported the proportion of exposed workers > 0%. Measures of health effects are only reported in groups with 6 or more cases",
          select_ind: "Select or search industry",
          select_occu: "Select or search occupation",
          select_disease: "Select disease",
          clear_select: "Clear Selections",
          measures_th: "Measures of Exposure",
          risk_effects_th: "Risk of adverse health effects (%)",
          sub_title: "Hover over icons to show % of data",
          how_title: "How to use this Data Tool",
          how1_industry:"View exposure information and health risk estimates across industry groups",
          how2_industry:"Select or search for an industry group from the dropdown menu",
          how3_industry:"Hover over icons for more information",
          how4_industry:"Use the dropdown menu to view results for a specific disease",
          how1_occupation:"View exposure information and health risk estimates across occupation groups",
          how2_occupation:"Select or search for an occupation group from the drop down menu or click the “+” icon to view more detailed groups",
          how3_occupation:"Hover over icons for more information",
          how4_occupation:"Use the dropdown menu to view results for a specific disease",
          interpret_title: "Interpreting the Data",
          interpret1: "These exposure metrics show the proportion, intensity level, and duration of exposure as reported by the Canadian Job Exposure Matrix (CANJEM).",
          interpret2: "Proportion exposed refers to the number of workers in a group that are exposed while at work. Exposure levels are relative to each other based on how they compare to known occupations/industries with low, medium, and high exposure (they are not related to occupational exposure limits). Exposure duration is measure as the estimated number of hours a group of workers are exposed to the hazard during a 40-hour work week.",
          interpret3: "The health risk estimates show the percent increase or decrease in the incidence rate (or ‘risk’) of a disease diagnosis among workers in a particular group compared to all other workers in the ODSS. The incidence rate is the number of disease cases diagnosed per worker per year of follow-up. A 50% increased rate in a particular group means that the group had a 50% increased rate of that disease compared to all other workers in the ODSS.",
          interpret4: "The percent difference in risk is based on the Hazard Ratio (HR), which can be viewed by hovering over the data in the table. The width of the 95% Confidence Interval (CI) is based on the number of cases in each group (i.e. the more cases, the narrower the interval).",
          interpret5: "HRs are estimated using Cox Proportional Hazards models and are adjusted for sex, year of birth, and age at start of follow-up.",
          interpret6: "Note that this measure of risk is compared to other workers in the ODSS and does not describe the probability of a disease diagnosis in a person’s lifetime.",
          chartTitleText: " exposure and health effects for ",
          gas_title: "Gas Fumes",
          sold_title: "Soldering Fumes",
          arc_title: "Arc Fumes",
          explore_arc: "../img/explore_arc.svg",
          explore_gas: "../img/explore_gas.svg",
          explore_soldering: "../img/explore_soldering.svg",
          arc_style: "cursor:pointer;width:30%;",
          gas_style: "cursor:pointer;width:30%;",
          soldering_style: "cursor:pointer;width:35%;",
          arc_link: "../img/arc_dark.svg",
          arc_link_active: "../img/arc_light.svg",
          gas_link: "../img/gas_dark.svg",
          gas_link_active: "../img/gas_light.svg",
          soldering_link: "../img/soldering_dark.svg",
          soldering_link_active: "../img/soldering_light.svg",
          explore_flour: "../img/explore_flour.svg",
          explore_grain: "../img/explore_grain.svg",
          flour_link: "../img/flour_dark.svg",
          flour_link_active: "../img/flour_light.svg",
          grain_link: "../img/grain_dark.svg",
          grain_link_active: "../img/grain_light.svg",
          flour_style: "cursor:pointer;width:35%;",
          grain_style: "cursor:pointer;width:35%;",
        },
        fr:{
          key_health: "Principaux effets sur la santé dans le Système de surveillance des maladies professionnelles",
          data_tool: "../img/data_tool_fr.svg",
          view_key: "voir les principaux résultats",
          download_text: "Télécharger l'infographie",
          by_industry: "Par industrie",
          by_occupation: "Par profession",
          results_text: "Les résultats sont présentés pour les groupes pertinents et suffisamment importants qui ont déclaré une proportion de travailleurs exposés supérieure à 0 %. Les mesures des effets sur la santé ne sont déclarées que pour les groupes comptant au moins six cas.",
          select_ind: "Sélectionnez ou cherchez une industrie",
          select_occu: "Sélectionnez ou cherchez une profession",
          select_disease: "Sélectionnez une maladie",
          clear_select: "Effacer les sélections",
          measures_th: "Mesures de l’exposition",
          risk_effects_th: "Risque d’effets nocifs sur la santé (%)",
          sub_title: "Placez le pointeur sur une icône pour afficher le % des données",
          how_title: "Comment utiliser cet outil de données",
          how1_industry:"Affichez l’information sur l’exposition et les estimations des risques pour la santé des différents groupes sectoriels",
          how2_industry:"Sélectionnez ou cherchez un groupe sectoriel dans le menu déroulant",
          how3_industry:"Placez le pointeur sur une icône si vous souhaitez obtenir de plus amples renseignements",
          how4_industry:"Utilisez le menu déroulant pour afficher les résultats pour une maladie en particulier",
          how1_occupation:"Affichez l’information sur l’exposition et les estimations des risques pour la santé des différents groupes professionnels",
          how2_occupation:"Sélectionnez ou cherchez un groupe professionnel dans le menu déroulant ou cliquez sur l’icône « + » pour afficher des groupes plus détaillés",
          how3_occupation:"Placez le pointeur sur une icône si vous souhaitez obtenir de plus amples renseignements",
          how4_occupation:"Utilisez le menu déroulant pour afficher les résultats pour une maladie en particulier",
          interpret_title: "Interprétation des données",
          interpret1: "Ces mesures d’exposition indiquent la proportion, le niveau d’intensité et la durée de l’exposition selon la CANJEM (Canadian Job Exposure Matrix [registre canadien de l’exposition au travail]).",
          interpret2: "La proportion de travailleurs exposés désigne le nombre de travailleurs dans un groupe qui sont exposés au travail. L'évaluation des niveaux d’exposition dépend de la façon dont ils se comparent à ceux de professions/industries connues où l’exposition est faible, moyenne ou élevée les niveaux ne sont pas liés aux limites d’exposition professionnelle). La durée de l’exposition correspond au nombre estimatif d’heures pendant lesquelles un groupe de travailleurs est exposé au danger pendant une semaine de travail de 40 heures.",
          interpret3: "Les estimations du risque pour la santé montrent l’augmentation ou la diminution en pourcentage du taux d’incidence (ou « risque ») d’un diagnostic de maladie chez les travailleurs d’un groupe particulier comparativement à tous les autres travailleurs du SSMP. Le taux d’incidence est le nombre de cas d'une maladie diagnostiqués par type de travailleur par année de suivi. Un taux 50 % plus élevé dans un groupe donné signifie que le groupe avait un taux d'incidence de cette maladie 50 % plus élevé que tous les autres travailleurs du SSMP.",
          interpret4: "La différence de risque en pourcentage est fondée sur le rapport de risque, (RR) que l’on peut consulter en survolant le point de données avec la souris. L’ampleur de l’intervalle de confiance à 95 % est fondée sur le nombre de cas dans chaque groupe (un plus grand nombre de cas resserre l’intervalle).",
          interpret5: "Les rapports de risque sont estimés à l’aide du modèle des risques proportionnels de Cox et sont rajustés en fonction du sexe, de l’année de naissance et de l’âge au début du suivi.",
          interpret6: "Veuillez noter que cette mesure du risque est comparée à celle des autres travailleurs dans le SSMP et ne décrit pas la probabilité d’un diagnostic de la maladie au cours de la vie d’une personne.",
          chartTitleText: " exposition et effets sur la santé pour ",
          gas_title: "Fumées de gaz",
          sold_title: "Fumées de soudage",
          arc_title: "Fumées de soudage à l’arc",
          explore_arc: "../img/explore_arc_fr.svg",
          explore_gas: "../img/explore_gas_fr.svg",
          explore_soldering: "../img/explore_soldering_fr.svg",
          arc_style: "cursor:pointer;width:47%;",
          gas_style: "cursor:pointer;width:40%;",
          soldering_style: "cursor:pointer;width:42%;",
          arc_link: "../img/arc_dark_fr.svg",
          arc_link_active: "../img/arc_light_fr.svg",
          gas_link: "../img/gas_dark_fr.svg",
          gas_link_active: "../img/gas_light_fr.svg",
          soldering_link: "../img/soldering_dark_fr.svg",
          soldering_link_active: "../img/soldering_light_fr.svg",
          explore_flour: "../img/explore_flour_fr.svg",
          explore_grain: "../img/explore_grain_fr.svg",
          flour_link: "../img/flour_dark_fr.svg",
          flour_link_active: "../img/flour_light_fr.svg",
          grain_link: "../img/grain_dark_fr.svg",
          grain_link_active: "../img/grain_light_fr.svg",
          flour_style: "cursor:pointer;width:40%;",
          grain_style: "cursor:pointer;width:40%;",
        },
      },
    }
  },
  
  head: {
    title:{
      inner:' Exposures | Ontario Occupational Disease Statistics'
    },
    script: [
      { src: '/js/popper.min.js'},
      { src: '/js/bootstrap.min.js'},
      { src: '/js/regular.min.js'}
    ]
  },
  mounted: function() {
    this.getExposures();
    this.getIndustries();
    this.getOccupations();
    this.getDiseases();
    this.getExpoIndustryData();
    this.getExpoOccupationData();
  },
  computed: {
    setLang : function () {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return lang;
    },
    locpath: function() {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      if (this.$route.name == "Sector") {
        return '?id=' + this.$route.query.id;
      } else {
        return '?locale='+lang;
      }
    },
    chartTitle: function() {
      let title = "";
      let occupations = this.occupations;
      let industries = this.industries;

      if (this.showIndustry == true) {
        if (this.select_industry == 0) {
          if (this.setLang == 'fr') {
            title = this.dropName + this.content[this.setLang].chartTitleText + "industries";
          } else {
            title = this.dropName + this.content[this.setLang].chartTitleText + "industries";
          }
        } else{
          if (this.setLang == 'fr') {
            title = this.dropName + this.content[this.setLang].chartTitleText + (industries[this.select_industry].industry_fr).toLowerCase();
          } else {
            title = this.dropName + this.content[this.setLang].chartTitleText + (industries[this.select_industry].industry).toLowerCase();
          }
        }
      } else {
        if (this.select_occupation == 0) {
          if (this.setLang == 'fr') {
            title = this.dropName + this.content[this.setLang].chartTitleText + "occupations";
          } else {
            title = this.dropName + this.content[this.setLang].chartTitleText + "occupations";
          }
        } else{
          if (this.setLang == 'fr') {
            title = this.dropName + this.content[this.setLang].chartTitleText + (occupations[this.select_occupation].occupation_fr).toLowerCase();
          } else {
            title = this.dropName + this.content[this.setLang].chartTitleText + (occupations[this.select_occupation].occupation).toLowerCase();
          }
        }
      }
      return title;
    },
    dropName : function () {
      let path1 = this.$route.query.id;
      var lang = this.setLang;
      if (this.exposures[path1] == undefined) {
        return this.dropText;
      } else {
        if (lang === 'fr'){
          return this.exposures[path1].exposure_name_fr;
        } else {
          return this.exposures[path1].exposure_name;
        }
      }
    },
    dropDescription:function () {
      let path1 = this.$route.query.id;
      var lang = this.setLang;
      if (this.exposures[path1] == undefined) {
        return this.dropText;
      } else {
        if (lang === 'fr'){
          return this.exposures[path1].description_fr;
        } else {
          return this.exposures[path1].description;
        }
      }
    },
    keyHealth:function () {
      let path1 = this.$route.query.id;
      var lang = this.setLang;
      if (this.exposures[path1] == undefined) {
        return this.dropText;
      } else {
        if (lang === 'fr'){
          return this.exposures[path1].health_effects_fr;
        } else {
          return this.exposures[path1].health_effects;
        }
      }
    },
    diseaseList: function() {
      let diseaseList = [];
      let dlist = [];
      let typet = this.tableType;
      let disease = this.diseases;
      if (typet == "industry") {
        let ind_data = this.industry_data;
        this.lodash.forEach(ind_data, function(i){
            diseaseList.push({'id':i.disease_id});
        });
      }else {
        let occu_group_data = this.occu_group_data;
        this.lodash.forEach(occu_group_data, function(i){
            diseaseList.push({'id':i.disease_id});
        });
      }

      this.lodash.forEach(diseaseList, function(d){
        dlist[d.id] = disease[d.id];
      });

      return dlist;
    },
  },
  methods: {
    reset() {
      // Reset selections
      this.select_occupation = 0;
      this.select_industry = 0;
      this.select_disease = 0;
      this.forceRerender();
      this.getExpoIndustryData();
      this.getExpoOccupationData();
    },
    forceRerender() {
      this.occupationKey += 1;
      this.diseaseKey += 1;
    },
    forceRerenderDisease() {
      this.diseaseKey += 1;
    },
    toggle_dataset(val) {
      // Toggle between "by industry" and "by occupation in data tool" //
      if (val == 0) {
        this.showIndustry = true;
        this.showOccupation = false;
      }else{
        this.showIndustry = false;
        this.showOccupation = true;
      }
    },
    getExposures() {
        var urloc = (window.location.origin == "http://localhost:8080") ? "https://odss.ccohs.ca" : window.location.origin;
        axios.get(urloc+"/php/data.php?action=getExposures").then(response => {
            this.exposures = response.data;
            let path1 = this.$route.query.id;
            if (path1) {
                  this.dropText = this.exposures[path1].exposure_name;
            } else {
                this.dropText = "CHOOSE";
            }
        });
    },
    getExposureIcon() {
      var images = require.context('../assets/', false, /\.svg$/);
      let path1 = this.$route.query.id;
      if (this.exposures[path1] != undefined) {
        this.sectorImg = this.exposures[path1].exposure_icon + ".svg";
        return images('./' + this.sectorImg);
      }
    },
   getIndustries() {
        let sid = this.$route.query.id;
        let ind = this.select_industry;
        var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
        axios.get(urloc+"/php/data.php?action=getExpoIndustries&sid="+sid+"&ind="+ind).then(response => {
            this.industries = response.data;
        });
    },
    getOccupations() {
        let sid = this.$route.query.id;
        let ind = this.select_occupation;
        var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
        axios.get(urloc+"/php/data.php?action=getExpoOccupations&sid="+sid+"&ind="+ind).then(response => {
            this.occupations = response.data;
        });
    },
    getExpoIndustryData() {
      let sid = this.$route.query.id;
      let ind = this.select_industry;
      let did = this.select_disease;
      var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
      axios.get(urloc+"/php/data.php?action=getExpoIndustryData&sid="+sid+"&ind="+ind+"&did="+did).then(response => {
        this.industry_groups = response.data.industry_groups;
        this.industry_subgroups = response.data.industry_subgroups;
        this.industry_intensity = response.data.industry_intensity;
        this.industry_data = response.data.industry_data;
        this.grouped_industry = response.data.group;
      });
    },
    getExpoOccupationData() {
      let sid = this.$route.query.id;
      let ind = this.select_occupation;
      let did = this.select_disease;
      var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
      axios.get(urloc+"/php/data.php?action=getExpoOccupationData&sid="+sid+"&ind="+ind+"&did="+did).then(response => {
        this.occupation_groups = response.data.occu_groups;
        this.occupation_subgroups = response.data.occu_subgroups;
        this.occu_group_intensity = response.data.occu_group_intensity;
        this.occu_sub_intensity = response.data.occu_subgroup_intensity;
        this.occu_group_data = response.data.occu_group_data;
        this.occu_sub_data = response.data.occu_sub_data;
        this.grouped_occupation = response.data.group;
      });
    },
    getDiseases() {
      var urloc = (window.location.origin == "http://localhost:8080") ? "https://www.occdiseasestats.ca" : window.location.origin;
      axios.get(urloc+"/php/data.php?action=getDiseases").then(response => {
          this.lodash.orderBy(response.data.diseases, 'disease_name', 'asc');
          this.diseases = response.data.diseases;
      });
    },
    changeDisease(selected) {
      this.select_disease = selected;
      this.getExpoIndustryData();
      this.getExpoOccupationData();
    },
    changeIndustry(selected) {
      this.select_industry = selected;
      this.getDiseases();
      this.getExpoIndustryData();
    },
    changeOccupation(selected) {
      this.select_occupation = selected;
      this.getDiseases();
      this.getExpoOccupationData();
    }
  },
  watch: {
    dropName: function() {
      this.exposureID = this.$route.query.id;
      this.getExposureIcon();
      this.showExplore = false;
    },
    visibleIndustry: function() {
      this.select_occupation = 0;
      this.getDiseases();
      if (this.visibleIndustry != 0) {
        this.select_description = (this.setLang == 'fr') ? this.industries[this.visibleIndustry].description_fr : this.industries[this.visibleIndustry].description;
      } else {
        this.select_description = '';
      }
    },
    select_industry: function() {
      this.getDiseases();
      this.getExpoIndustryData();
      this.getIndustries();
    },
    select_occupation: function() {
      
      this.getExpoOccupationData();
      this.getOccupations();
      this.getDiseases();
    },
    exposureID: function() {
      this.select_occupation = 0;
      this.select_industry = 0;
      this.select_disease = 0;
      this.getExposures();
      this.getIndustries();
      this.getOccupations();
      this.getExpoIndustryData();
      this.getExpoOccupationData();
      this.getDiseases();
    },
    setLang: function(){
      if (this.select_suboccupation > 0) {
        this.select_description = (this.setLang == 'fr') ? this.occupation_groups[this.select_occupation].subgroups[this.select_suboccupation].description_fr : this.occupation_groups[this.select_occupation].subgroups[this.select_suboccupation].description;
      } else if(this.select_suboccupation == 0 && this.select_occupation > 0) {
        this.select_description = (this.setLang == 'fr') ? this.occupation_groups[this.select_occupation].description_fr : this.occupation_groups[this.select_occupation].description;
      } else if (this.sub_occupation == 0 && this.select_suboccupation == 0 && this.visibleIndustry > 0) {
        this.select_description = (this.setLang == 'fr') ? this.industries[this.visibleIndustry].description_fr : this.industries[this.visibleIndustry].description;
      } else {
        this.select_description = '';
      }
    }
  }
}
</script>


<style type="text/css">
@import "~font-awesome/css/font-awesome.css";
hr {
  margin-top:-10px;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  color: #2c3e50;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

h1, h2 {
  font-weight: normal;
}

h2 {
  color:#E7401C;
}




.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  font-size:14px;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid black;
  background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  left: 8px;
  content: " ";
  display: block;
  background: orange;
}

.clearBtn {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight:bold;
  background-color: #E7401C;
  padding:8px;
  font-size:14px;
  width:100%;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

main {
  margin:0 auto;
  padding:0;
  width:70%;
}

.video {
  width:100%;
  height:28em;
  border:solid 1px black;
  margin:0 auto;
  padding:0;
}

.introText {
  margin-top:3%;
  font-size:1.5em;
  text-align:left;
}

#sectors { 
  border: 1px solid #E7401C;
  border-radius: 120px;
  margin-top:3%;
}

html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, section, time, mark, audio, video {
    margin: 0 auto;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;

}

/** accordion styles */
.btn-info.focus, .btn-info:focus, .btn:focus, .info:focus .btn {
  box-shadow:none !important;
  background-color:#0A2940;
  border:none;
}

.btn {
  background-color:#0A2940;
  border:none;
  text-align:left !important;
}
.accTitle {text-align: left};


.tooltip.popover .popover-inner {
background: #f9f9f9;
color: black;
padding: 24px;
border-radius: 5px;
box-shadow: 0 5px 30px rgba(0, 0, 0, .1);
}
.tooltip.popover .popover-arrow {
border-color: #E7401C;
}


.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: white;
  color: black;
  border-radius: 16px;
  padding: 5px 10px 4px;
  border: 4px solid;
  border-color: #E7401C;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
  text-align:left;
  min-width:400px;
  font-size:12px;
  border: 2px solid #E7401C;
}

.tooltip.popover .popover-arrow {
  border-color: #E7401C;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.disease-td {
  background-color:rgba(250, 55, 48, 0.1) !important;
}

.viewKeyFindings{
  color:#2c3e50;
}

.viewKeyFindings:hover{
  color:#E7401C;
  text-decoration: underline;
}
</style>
